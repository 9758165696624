// ProfileSettingsPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, setDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { auth, firestore } from './firebase';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';

import bannedWords from './utils/engBadWords.json';

const MAX_SCREEN_NAME_LENGTH = 24;

const ProfileSettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [screenName, setScreenName] = useState('');
  const [newScreenName, setNewScreenName] = useState('');
  const [editingScreenName, setEditingScreenName] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorToggle, setErrorToggle] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);

        // Refresh token to get latest custom claims
        await currentUser.getIdToken(true);

        // Get ID token with claims
        const token = await currentUser.getIdTokenResult();
        setIsAdmin(token.claims.admin || false);

        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setScreenName(userData.screenName || '');
          if (!userData.screenName) {
            setEditingScreenName(true);
          }
        } else {
          setEditingScreenName(true);
        }
      } else {
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);


  const checkScreenNameUniqueness = async () => {
    const q = query(collection(firestore, 'users'), where('screenName', '==', newScreenName));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const containsBannedWords = (text) => {
    const textLower = text.toLowerCase();
    return bannedWords.some(word => textLower.includes(word));
  };

  const handleSaveScreenName = async () => {
    if (!newScreenName) {
      setErrorMessage('Please enter a screen name.');
      return;
    }

    if (newScreenName.length > MAX_SCREEN_NAME_LENGTH) {
      setErrorMessage(`Screen name cannot exceed ${MAX_SCREEN_NAME_LENGTH} characters.`);
      return;
    }

    if (containsBannedWords(newScreenName)) {
      setErrorMessage('Screen name contains inappropriate words. Please choose another.');
      return;
    }

    setLoading(true);
    try {
      const isUnique = await checkScreenNameUniqueness();
      if (!isUnique) {
        setErrorToggle(!errorToggle);
        setErrorMessage(errorToggle ? 'Screen name is already taken. Please try another.' : 'That screen name is taken, please choose another.');
        setLoading(false);
        return;
      }

      const currentUser = auth.currentUser;
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await setDoc(userDocRef, { screenName: newScreenName }, { merge: true });
      setScreenName(newScreenName);
      setNewScreenName('');
      setEditingScreenName(false);
    } catch (error) {
      console.error('Error saving screen name:', error);
      setErrorMessage('Error saving screen name. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditScreenName = () => {
    setNewScreenName(screenName);
    setEditingScreenName(true);
  };

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const handleAdmin = () => {
    navigate('/admin');
  };

  const openUserAgreement = () => {
    navigate('/disclaimer');
  };

  const sendEmail = () => {
    const email = 'quiznect@gmail.com';
    const subject = 'Contact Support';
    const body = 'Hi there,\n\nI need help with...';
    const url = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = url;
  };

  return (
    <div className="flex flex-col items-center p-5 bg-gray-100 min-h-screen relative">
      <div className="fixed top-0 left-0 right-0 h-15 flex justify-between items-center bg-[#9C27B0] p-4 text-white">
        <button onClick={() => navigate('/landing')} className="bg-transparent border-none text-white cursor-pointer">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl" />
        </button>
        <h1 className="text-xl font-bold flex-grow text-center">Profile</h1>
      </div>
      {loading ? (
        <div className="mt-20">Loading...</div>
      ) : (
        <div className="flex flex-col items-center mt-20">
          <div className="w-12 h-12 rounded-full flex justify-center items-center bg-gray-300">
            <FontAwesomeIcon icon={faUser} size="3x" color="#9C27B0" />
          </div>
          <h2 className="text-2xl font-bold mt-2">{screenName || 'Set your screen name'}</h2>
          <p className="text-lg text-gray-500 mb-5">{user?.email}</p>

          {editingScreenName ? (
            <div className="flex flex-col items-center">
              <input
                type="text"
                className="p-2 border border-purple-600 rounded mb-2 text-lg w-52"
                placeholder="Enter your screen name"
                value={newScreenName}
                maxLength={MAX_SCREEN_NAME_LENGTH}
                onChange={(e) => setNewScreenName(e.target.value)}
              />
              {errorMessage && (
                <p className="mt-2 text-red-500">{errorMessage}</p>
              )}
              <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={handleSaveScreenName} disabled={loading}>
                {loading ? 'Saving...' : 'Save Screen Name'}
              </button>
              <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={() => setEditingScreenName(false)}>
                Cancel
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={handleEditScreenName}>
                Edit Screen Name
              </button>
              <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={handleLogout}>
                Log Out
              </button>
              <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={() => navigate('/user-scores')}>
                View Scores
              </button>
            </div>
          )}

          {isAdmin && (
            <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={() => navigate('/admin')}>
              Admin
            </button>
          )}

          <div className="absolute bottom-24 w-full flex flex-col items-center">
            <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={openUserAgreement}>
              User Agreement
            </button>
            <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={sendEmail}>
              Contact Us
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSettingsPage;




/* 

const version = "1.0.3"; // Replace with actual version from your config
  const buildNumber = "17"; // Replace with actual build number from your config



          <button className="profile-button profile-danger">Delete Account</button>



           <div className="version-container">
        <p>Version: {version}</p>
        <p>Build: {buildNumber}</p>
      </div>


          */
