// QuizGenerationProgressPage.js
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { doc, getDoc, onSnapshot, setDoc, arrayUnion } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { browserLocalPersistence, setPersistence } from 'firebase/auth';
import ScreenNameContext from './ScreenNameContext';
import { logEvent } from 'firebase/analytics';
import { v4 as uuidv4 } from 'uuid';

function QuizGenerationProgressPage() {
  const { screenName } = useContext(ScreenNameContext);
  const [progress, setProgress] = useState({
    status: 'Starting...',
    percentage: 0,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [quizGenerationStarted, setQuizGenerationStarted] = useState(false);
  const [learningGoal, setLearningGoal] = useState("");
  const [isQuizGenerationCompleted, setIsQuizGenerationCompleted] = useState(false);

  // Initialize Firebase Functions
  const functions = useMemo(() => getFunctions(undefined, 'us-central1'), []);
  const publishQuizGenerationRequest = useMemo(
    () => httpsCallable(functions, 'publishQuizGenerationRequest'),
    [functions]
  );

  // Set authentication persistence to 'local'
  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states will now persist in local storage.
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });
  }, []);

  // Set up an observer on Firebase Auth to ensure it's initialized
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthInitialized(true);
        setCurrentUser(user);
      } else {
        setIsAuthInitialized(false);
        setCurrentUser(null);
        navigate('/login'); // Adjust the path as needed
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  function normalizeString(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .replace(/[^\w-_.]/g, ''); // Remove non-alphanumeric characters (keeping underscores, dashes, and periods)
  }

  function createPurchaseID(timestamp, userUid, learningGoal) {  
    const formattedTimestamp = timestamp.toISOString().replace(/[-:T]/g, '').slice(0, 14); // Remove special characters and limit to 14 chars
    const normalizedLearningGoal = normalizeString(learningGoal);

    // Generate a random string of 4 alphanumeric characters
    const randomString = Math.random().toString(36).substring(2, 6);
    const quizGenStr = "QGen"
    // Construct purchaseId
    const purchaseId = `${formattedTimestamp}_${quizGenStr}_${userUid}_${normalizedLearningGoal}_${randomString}`;

    // Limit to 256 characters
    return purchaseId.length <= 256 ? purchaseId : purchaseId.slice(0, 256);
  }

  // Effect to verify payment when the component mounts
  useEffect(() => {
    if (!isAuthInitialized || !currentUser || quizGenerationStarted) return;

    const verifyPaymentAndInitiateQuizGeneration = async () => {
      const sessionId = new URLSearchParams(location.search).get('session_id');
      const source = location.state?.source || 'purchase'; // 'purchase' or 'promoCode'

      if (source === 'promoCode') {
        // For promo code, the Pub/Sub message has already been published by the redeemPromoCode function.
        // Navigate to this page to monitor progress.
        setQuizGenerationStarted(true);
        return;
      }

      if (!sessionId) {  // Session ID is the Stripe payment session
        setErrorMessage('No session ID found. Please try again.');
        return;
      }

      // Avoid refresh issues during purchase confirmation and quizGen launch
      const userDocRef = doc(firestore, 'purchasedLearningGoals', currentUser.uid);
      const docSnap = await getDoc(userDocRef);
      const existingPurchases = docSnap.exists() ? docSnap.data().learningGoalPaymentInfo || [] : [];
    
      // Check if this learning goal has already been processed
      const hasPendingOrCompletedQuiz = existingPurchases.some(purchase => 
        purchase.learningGoal === learningGoal && 
        (purchase.quizGenerationStatus === 'pending' || purchase.quizGenerationStatus === 'completed')
      );

      if (hasPendingOrCompletedQuiz) {
        setErrorMessage('Quiz generation already in progress or completed.');
        return;
      }

      try {
        const response = await fetch(
          `https://us-central1-quiznect.cloudfunctions.net/retrieveSession?sessionId=${sessionId}`
        );

        if (!response.ok) {
          setErrorMessage('Failed to verify payment. Please try again.');
          return;
        }

        const session = await response.json();

        if (session.payment_status === 'paid') {
          const userEmail = currentUser.email;
          const userDocRef = doc(firestore, 'learningGoals', currentUser.uid);

          let learningGoal = "";
          let learningPlan = "";
          let enhancedLearningPlan = "";
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            learningGoal = data.learningGoal || "";
            learningPlan = data.learningPlan || "";
            enhancedLearningPlan = data.enhancedLearningPlan || "";
            setLearningGoal(learningGoal);
          }

          if (learningGoal.length === 0) {
            setErrorMessage(
              'No learning goal found for this user. Please contact support.'
            );
            return;
          }

          const userUid = currentUser.uid; // Use UID instead of email
          console.log(" userUid: " + userUid);
          const userPurchaseDocRef = doc(firestore, 'purchasedLearningGoals', userUid);

          // Extract payment details from Stripe session
          const paymentDetails = {
            amount: session.amount_total, // Amount in cents
            currency: session.currency.toUpperCase(), // e.g., 'USD'
          };

          console.log(" paymentDetails.amount: " + paymentDetails.amount);
          console.log(" paymentDetails.currency : " + paymentDetails.currency);

          // Add purchase info to purchasedLearningGoals
          const currTimeStamp = new Date();
          const purchaseUid = createPurchaseID(currTimeStamp, userUid, learningGoal);
          try {
            await setDoc(userPurchaseDocRef, {
              learningGoalPaymentInfo: arrayUnion({
                learningGoal: learningGoal,
                purchaseStatus: 'paid',
                purchaseId: purchaseUid,
                purchaseTimestamp: currTimeStamp,
                paymentDetails: paymentDetails,
                quizGenerationStatus: 'pending',
                userEmail: userEmail,
                userUid: userUid,
                screenName: screenName,
                learningPlan: learningPlan,
                enhancedLearningPlan: enhancedLearningPlan,
              }),
            });
            console.log(
              'Successfully recorded purchase in purchasedLearningGoals'
            );
          } catch (error) {
            console.error(
              'Error updating purchasedLearningGoals:',
              error
            );
            setErrorMessage(
              'Error recording purchase details. Please try again.'
            );
            return;
          }

          try {
            await publishQuizGenerationRequest({
              userEmail,
              learningGoal,
              userUid,
              purchaseUid,
              learningPlan: learningPlan,
              enhancedLearningPlan: enhancedLearningPlan,
            });
            console.log('Successfully published quiz generation request');
          } catch (error) {
            console.error('Error publishing quiz generation request:', error);
            setErrorMessage('Error starting quiz generation. Please try again.');
          }

        } else {
          setErrorMessage('Payment not verified. Please complete your payment.');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setErrorMessage('Error verifying payment. Please try again.');
      }
    };

    verifyPaymentAndInitiateQuizGeneration();
    setQuizGenerationStarted(true);
  }, [isAuthInitialized, currentUser, quizGenerationStarted, location.search]);

  // Effect to listen to Firestore updates for quiz generation progress
  useEffect(() => {
    if (!isAuthInitialized || !currentUser) return;

    const userDocRef = doc(firestore, 'learningGoals', currentUser.uid);

    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      const data = docSnap.data();
      setProgress({
        status: data.quizGenerationStatus || 'Starting...',
        percentage: data.progressPercentage || 0,
      });

      if (data.quizGenerationStatus === 'Completed') {
        setIsQuizGenerationCompleted(true);
      }
    });

    return () => unsubscribe();
  }, [isAuthInitialized, currentUser]);

  const handleGoHome = () => {
    navigate('/landing');
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={handleGoHome} />
        </div>
        <h1 className="text-xl font-bold">Quiz Generation Progress</h1>
        <div></div>
      </nav>

      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg mt-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Generating Quiz...</h2>
        <h3 className="text-xl font-semibold mb-4 text-center">{learningGoal}</h3>

        {!isQuizGenerationCompleted ? (
          <>
            <div className="w-full bg-gray-200 rounded-full h-6 mb-6">
              <div
                className="bg-green-600 h-6 rounded-full transition-all duration-500"
                style={{ width: `${progress.percentage}%` }}
              ></div>
            </div>
            <p className="text-center text-gray-700">{progress.status}</p>
          </>
        ) : (
          <div className="text-center">
            <p className="text-green-700 font-semibold mb-4">Quiz generation completed!</p>
            <button
              onClick={() => navigate('/learning-focus')}
              className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors"
            >
              Go to Learning Focus
            </button>
          </div>
        )}

        {errorMessage && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mt-4">
            <p className="text-center">{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuizGenerationProgressPage;

