// TriviaScreen.js
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHome, faClock, faTrophy, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import triviaFileMapping from './quizdata/TriviaFileMapping';
import { auth, firestore } from './firebase';
import { collection, addDoc, doc, getDoc, setDoc, updateDoc, deleteField, deleteDoc, serverTimestamp } from 'firebase/firestore';
import ScreenNameContext from './ScreenNameContext';
import LoadingSpinner from './LoadingSpinner';
import { handleQuizCompletion } from './components/quizCompletionHandler';

function TriviaScreen() {
  const { screenName } = useContext(ScreenNameContext);
  const [quizData, setQuizData] = useState(null);
  ///const [quizMetadata, setQuizMetadata] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [sessionCorrectCount, setSessionCorrectCount] = useState(0);
  const [sessionIncorrectCount, setSessionIncorrectCount] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [timer, setTimer] = useState(25); // 25 seconds timer
  const [userAnswers, setUserAnswers] = useState([]);
  const [questionTimeBonus, setQuestionTimeBonus] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedQuizId, setQuizId] = useState(null);
  const [quizType, setQuizType] = useState('');
  const [userId, setUserId] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const [tagFilter, setTagFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // FNV-1a hash function (for consistency with PlayQuiz.js)
  const fnv1aHash = (str) => {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i);
      hash = Math.imul(hash, 0x01000193);
    }
    return hash >>> 0;
  };

  // Seeded random number generator (Mulberry32) - For shuffle consistency
  function mulberry32(a) {
    return function() {
      let t = a += 0x6D2B79F5;
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
  }

  // Seeded shuffle function - For shuffle consistency
  function shuffleArraySeed(array, seed) {
    const random = mulberry32(seed);
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(random() * currentIndex);
      currentIndex -= 1;

      // Swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  // Combined useEffect to fetch quizId, metadata, and quiz data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Extract parameters from location.state
      const { quizFile, quizType, quizName, categoryName, quizState } = location.state || {};
      const tagFilterParam = location.state?.tagFilter || '';
      const tournamentIdParam = location.state?.tournamentId || '';

      // Validate essential quiz information
      if (!quizFile || !quizType || !quizName) {
        console.log("location.state:", location.state);
        console.log("quizFile:", quizFile);
        console.log("quizType:", quizType);
        console.log("quizName:", quizName);
        alert('Missing quiz information.');
        navigate('/trivia');
        setLoading(false);
        return;
      }

      // Derive quizId from quizFile if not provided
      let quizId = location.state.quizId || quizFile; // Assuming quizFile is unique and can serve as quizId
      if (!location.state.quizId) {
        console.warn('quizId was missing. Derived quizId from quizFile:', quizId);
      }

      // Set state variables
      setQuizId(quizId);
      setQuizType(quizType);
      setTournamentId(tournamentIdParam);
      setTagFilter(tagFilterParam);

      // Get current user
      const currentUser = auth.currentUser;
      if (!currentUser) {
        // Redirect to login or show a modal
        alert('You need to be logged in to take the quiz.');
        navigate('/login');
        setLoading(false);
        return;
      }

      const userUid = currentUser.uid;
      setUserId(userUid);

      try {
        // Load quiz data from local mapping
        const data = triviaFileMapping[quizFile];
        if (!data || !data.questions) {
          console.error('Quiz data not found in triviaFileMapping.');
          alert('Quiz data is corrupted or missing.');
          navigate('/landing');
          setLoading(false);
          return;
        }

        let questions = data.questions;
        
        console.log(" questions.length: " + questions.length);

        // Ensure at least 10 questions are available
        if (questions.length < 10) {
          alert('This quiz does not have enough enabled questions to start.');
          if (quizId) {
            navigate(`/quizpage?id=${quizId}`);
          } else {
            navigate('/landing');
          }
          setLoading(false);
          return;
        }

        // Reference to the quiz state document
        const quizStateDocRef = doc(firestore, 'userQuizStates', `${userUid}_${quizId}_${quizType}`);
        const quizStateDoc = await getDoc(quizStateDocRef);

        if (quizType === 'randomQuiz10Timed') {
          if (quizStateDoc.exists()) {
            // Quiz state exists, load selectedQuestions and other state
            const savedState = quizStateDoc.data();

            setCurrentQuestionIndex(savedState.currentQuestionIndex || 0);
            setUserAnswers(savedState.userAnswers || []);
            setSessionCorrectCount(savedState.sessionCorrectCount || 0);
            setSessionIncorrectCount(savedState.sessionIncorrectCount || 0);

            if (savedState.selectedQuestions && savedState.selectedQuestions.length === 10) {
              setQuizData({ 
                questions: savedState.selectedQuestions, 
                quizName: quizName, 
                categoryName: categoryName || '' 
              });
            } else {
              console.warn('Selected questions are missing or incomplete in Firestore. Selecting new questions.');
              // Perform random shuffle and select new questions
              const shuffledQuestions = shuffleArray([...questions]);
              const selectedQuestions = shuffledQuestions.slice(0, 10);
              setQuizData({ 
                questions: selectedQuestions, 
                quizName: quizName, 
                categoryName: categoryName || '' 
              });

              // Update Firestore with new selectedQuestions and reset state
              const questionStartTime = new Date().toISOString();

              await updateDoc(quizStateDocRef, {
                selectedQuestions: selectedQuestions,
                currentQuestionIndex: 0,
                userAnswers: [],
                sessionCorrectCount: 0,
                sessionIncorrectCount: 0,
                timerStartTime: questionStartTime, // New field
                timestamp: serverTimestamp(),
              });

              // Reset local state
              setCurrentQuestionIndex(0);
              setUserAnswers([]);
              setSessionCorrectCount(0);
              setSessionIncorrectCount(0);
            }

            // Determine remaining time based on timerStartTime
            const timerStartTime = savedState.timerStartTime ? new Date(savedState.timerStartTime) : new Date();
            const now = new Date();
            const elapsedSeconds = Math.floor((now - timerStartTime) / 1000);
            const timeLimit = 25; // 25 seconds per question

            if (savedState.userAnswers && savedState.userAnswers.some(a => a.questionIndex === savedState.currentQuestionIndex)) {
              // Question has been answered or timed out
              const currentAnswer = savedState.userAnswers.find(a => a.questionIndex === savedState.currentQuestionIndex);
              setSelectedAnswer(currentAnswer.selectedAnswer);
              setIsCorrect(currentAnswer.isCorrect);
              setTimer(0); // No timer needed
            } else {
              // Question is active, set remaining time
              const remainingTime = timeLimit - elapsedSeconds;
              console.log(" remainingTime: " + remainingTime);
              if (remainingTime <= 0) {
                // Time has already expired, mark as timeout
                await handleAnswerSelection(null);
              } else {
                setTimer(remainingTime);
              }
            }
          } else {
            // No existing state, perform random shuffle and select questions
            const shuffledQuestions = shuffleArray([...questions]);
            const selectedQuestions = shuffledQuestions.slice(0, 10);
            setQuizData({ 
              questions: selectedQuestions, 
              quizName: quizName, 
              categoryName: categoryName || '' 
            });

            // Record the start time
            const questionStartTime = new Date().toISOString();

            // Create initial state in Firestore with selectedQuestions and start time
            await setDoc(quizStateDocRef, {
              userId: userUid,
              quizId: quizId,
              quizType: quizType,
              quizName: quizName,
              screenName: screenName,
              currentQuestionIndex: 0,
              userAnswers: [],
              sessionCorrectCount: 0,
              sessionIncorrectCount: 0,
              selectedQuestions: selectedQuestions,
              timerStartTime: questionStartTime, // New field
              timestamp: serverTimestamp(),
            });
          }
        } else {
          // Handle other quiz types (e.g., 'Quiz10Tournament') as per existing logic
          if (quizStateDoc.exists()) {
            // Load existing state
            const savedState = quizStateDoc.data();

            setCurrentQuestionIndex(savedState.currentQuestionIndex || 0);
            setUserAnswers(savedState.userAnswers || []);
            setSessionCorrectCount(savedState.sessionCorrectCount || 0);
            setSessionIncorrectCount(savedState.sessionIncorrectCount || 0);
          } else {
            // No existing state, create initial state in Firestore
            await setDoc(quizStateDocRef, {
              userId: userUid,
              quizId: quizId,
              quizType: quizType,
              quizName: quizName,
              screenName: screenName,
              currentQuestionIndex: 0,
              userAnswers: [],
              sessionCorrectCount: 0,
              sessionIncorrectCount: 0,
              timestamp: serverTimestamp(),
            });
          }

          // Shuffle based on existing logic for other quiz types
          if (quizType === 'Quiz10Tournament' && questions) {
            const seed = fnv1aHash(tournamentIdParam);
            const allQuestions = [...questions];
            const shuffledQuestions = shuffleArraySeed(allQuestions, seed);
            questions = shuffledQuestions.slice(0, 10);
          } // else, keep all questions

          setQuizData({ 
            questions, 
            quizName: quizName, 
            categoryName: categoryName || '' 
          });
        }
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        alert('Failed to load quiz data. Please try again later.');
        navigate('/landing');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.state, navigate, screenName]);

  // Reference to the quiz state document
  const quizStateDocRef = useMemo(() => {
    if (userId && selectedQuizId && quizType) {
      return doc(firestore, 'userQuizStates', `${userId}_${selectedQuizId}_${quizType}`);
    } else {
      return null;
    }
  }, [userId, selectedQuizId, quizType]);

  // Timer effect
  useEffect(() => {
    let interval = null;

    // Determine if the current question has been answered
    const currentAnswer = userAnswers.find(
      (answer) => answer.questionIndex === currentQuestionIndex
    );

    if (isCorrect === null && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            handleAnswerSelection(null); // Time's up
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, userAnswers, currentQuestionIndex, isCorrect]);

  useEffect(() => {
    if (quizData) {
      const question = quizData.questions[currentQuestionIndex];
      const correctAnswer = question.correctAnswer;
      const options = question.options;

      // Shuffle options with the helper function
      const shuffled = shuffleOptions(options, correctAnswer);

      setShuffledOptions(shuffled);
      setCorrectAnswer(correctAnswer);
    }
  }, [quizData, currentQuestionIndex]);

  // Existing shuffleArray function (Fisher-Yates Shuffle)
  const shuffleArray = (array) => {
    // Make a copy to avoid mutating the original array
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // New helper function to handle shuffling with "All of the above" at the bottom
  const shuffleOptions = (options, correctAnswer) => {
    if (correctAnswer === "All of the above" || correctAnswer === "All the above" || correctAnswer === "None of the above" ) {
      // Separate "All of the above" from the other options
      const allOfTheAboveOption = options.find(option => option === correctAnswer);
      const otherOptions = options.filter(option => option !== correctAnswer);
      
      // Shuffle the other options
      const shuffledOtherOptions = shuffleArray(otherOptions);
      
      // Append "All of the above" at the end
      return [...shuffledOtherOptions, allOfTheAboveOption];
    } else {
      // Shuffle all options normally
      return shuffleArray(options);
    }
  };

  
const handleAnswerSelection = async (answer) => {
  if (!quizData) {
    console.error('Cannot handle answer selection because quizData is null');
    return;
  }

  if (selectedAnswer !== null) return; // Prevent re-answering

  setSelectedAnswer(answer);
  const question = quizData.questions[currentQuestionIndex];
  const answerIsCorrect = answer === question.correctAnswer;
  setIsCorrect(answerIsCorrect);

  if (answerIsCorrect) {
    setSessionCorrectCount((prevCount) => prevCount + 1);
  } else {
    setSessionIncorrectCount((prevCount) => prevCount + 1);
  }

  // Update userAnswers
  const updatedUserAnswers = [
    ...userAnswers,
    {
      questionIndex: currentQuestionIndex,
      question: question.question,
      selectedAnswer: answer,
      correctAnswer: question.correctAnswer,
      isCorrect: answerIsCorrect,
    },
  ];
  setUserAnswers(updatedUserAnswers);

  // Calculate completion percentage
  const newCompletionPercentage = ((updatedUserAnswers.length) / quizData.questions.length) * 100;
  setCompletionPercentage(newCompletionPercentage.toFixed(2));

  // Update Firestore
  if (quizStateDocRef) {
    await updateDoc(quizStateDocRef, {
      userAnswers: updatedUserAnswers,
      sessionCorrectCount: answerIsCorrect ? sessionCorrectCount + 1 : sessionCorrectCount,
      sessionIncorrectCount: !answerIsCorrect ? sessionIncorrectCount + 1 : sessionIncorrectCount,
      timerStartTime: deleteField(), // Remove timerStartTime
      completionPercentage: newCompletionPercentage.toFixed(2),
      timestamp: serverTimestamp(),
    });
  }
};
  

  const handleNextQuestion = async () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      const newQuestionIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newQuestionIndex);
      setSelectedAnswer(null);
      setIsCorrect(null);
      setTimer(25); // Start timer for new question

      // Record the start time
      const questionStartTime = new Date().toISOString();

      // Update Firestore with new question index and start time
      if (quizStateDocRef) {
        await updateDoc(quizStateDocRef, {
          currentQuestionIndex: newQuestionIndex,
          selectedAnswer: null,
          isCorrect: null,
          timerStartTime: questionStartTime, // New field
          timestamp: serverTimestamp(),
        });
      }
    } else {
      handleExitQuiz();
    }
  };

  const handleExitQuiz = async () => {
    const totalPoints = sessionCorrectCount * 10;
    const totalBonusPoints = questionTimeBonus.reduce((acc, bonus) => acc + bonus, 0);
    const totalScore = totalPoints + totalBonusPoints;

    try {
      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.email) {
        alert('Please log in to save your quiz results');
        navigate('/');
        return;
      }

      // Save quiz results to Firestore
      await saveQuizResultsToFirestore(totalScore);

      console.log(" quiz Completed - quizData.quizName: " + quizData.quizName);
      console.log(" quiz Completed - quizType: " + quizType);
      console.log(" quiz Completed - selectedQuizId: " + selectedQuizId);

      navigate('/local-trivia-summary', {
        state: {
          correctCount: sessionCorrectCount,
          incorrectCount: sessionIncorrectCount,
          completionPercentage,
          totalScore,
          totalPoints,
          totalBonusPoints,
          quizName: quizData.quizName || '', // Include quizName
          quizType: quizType || '',
          quizId: selectedQuizId || '',
          userAnswers, // Include userAnswers
          tournamentId: tournamentId || '', // Include if applicable
        },
      });
    } catch (error) {
      console.error('Error saving quiz results:', error);
      alert('Error saving quiz results. Please try again.');
    }
  };

  const saveQuizResultsToFirestore = async (totalScore) => {
    // Use quizData.quizName to ensure quizName is defined
    const quizTypeLocal = quizType;
    const quizNameLocal = quizData.quizName;
    const quizFileLocal = location.state.quizFile;

    const currentUser = auth.currentUser;

    if (!currentUser) {
      alert('You need to be logged in to save your quiz results.');
      return;
    }

    const userUid = currentUser.uid;
    const userEmail = currentUser.email;

    // Determine the collection name based on quizType
    let collectionName = '';

    if (quizTypeLocal === 'dailyTrivia') {
      collectionName = 'dailyTrivia';
      // Additional logic for daily quiz (e.g., updating streak)
    } else if (quizTypeLocal === 'randomQuiz10Timed') {
      collectionName = 'randomQuizAnyCategory10Timed';
    } else if (quizTypeLocal.startsWith('trivia')) {
      const categoryName = quizData.categoryName || 'General';
      collectionName = `trivia${categoryName.replace(/\s+/g, '')}`;
    } else {
      // Default collection
      collectionName = 'quizResults';
    }

    // Reference to the collection
    const quizResultsRef = collection(firestore, collectionName);

    // Save the result
    await addDoc(quizResultsRef, {
      userUid,
      userEmail,
      screenName, // Use screenName from context
      quizName: quizNameLocal,
      quizFile: quizFileLocal,
      quizType: quizTypeLocal,
      score: totalScore,
      correctCount: sessionCorrectCount,
      incorrectCount: sessionIncorrectCount,
      timestamp: serverTimestamp(),
      tournamentId: tournamentId || '',
    });
  };

  const handleFlagQuestion = () => {
    navigate('/q-feedback', {
      state: {
        quizFile: location.state.quizFile,
        questionIndex: currentQuestionIndex,
        questionText: quizData.questions[currentQuestionIndex].question,
        quizState: {
          currentQuestionIndex,
          selectedAnswer,
          sessionCorrectCount,
          sessionIncorrectCount,
          completionPercentage,
          shuffledOptions,
          quizData,
          timer,
          userAnswers,
        },
      },
    });
  };

  const handleGoHome = () => {
    navigate('/landing');
  };

  const handleGoBackToQuizPage = () => {
    if (selectedQuizId) {
      navigate(`/quizpage?id=${selectedQuizId}`);
    } else {
      console.error('quizId is not available');
      alert('Cannot navigate back to Quiz Page. quizId is missing.');
    }
  };

  if (loading || !quizData || quizData.questions.length === 0) {
    return <LoadingSpinner />;
  }

  const question = quizData.questions[currentQuestionIndex];
  const explanationHtml = selectedAnswer ? question.explanation : '';

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        {/* Left Side: Home Button */}
        <div className="flex items-center">
          <FontAwesomeIcon 
            icon={faHome} 
            className="text-white text-2xl cursor-pointer mr-4" 
            onClick={handleGoHome} 
            title="Home"
          />
        </div>

        {/* Center: Question Indicator */}
        <h1 className="text-xl font-bold">
          {quizType === 'Quiz10Tournament' && (
            <FontAwesomeIcon icon={faTrophy} className="text-yellow-400 text-2xl mr-2" />
          )}
          Question {currentQuestionIndex + 1} of {quizData.questions.length}
        </h1>

        {/* Right Side: Timer & Flag Button */}
        <div className="flex items-center space-x-4">
          {/* Timer Display */}
          {isCorrect === null && (
            <div className="flex items-center">
              <FontAwesomeIcon icon={faClock} className="text-white text-lg mr-2" />
              <span className="text-lg font-bold">{timer}</span>
            </div>
          )}
          {/* Flag Button */}
          {selectedAnswer !== null && (
            <button 
              className="bg-[#9C27B0] text-white p-2 rounded-lg cursor-pointer" 
              onClick={handleFlagQuestion}
              title="Flag this question"
            >
              <FontAwesomeIcon icon={faFlag} />
            </button>
          )}
        </div>
      </nav>


      {/* Quiz Question */}
      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg mt-8">
        <div 
          className="text-lg font-semibold text-center mb-4" 
          dangerouslySetInnerHTML={{ __html: question.question.replace(/\n/g, '<br/>') }} 
        />

        <div className="grid gap-4">
          {shuffledOptions.map((option, index) => {
            // Determine if the current question has been answered
            const isAnswered = userAnswers.some(
              (answer) => answer.questionIndex === currentQuestionIndex
            );

            const isCorrectOption = option === correctAnswer;

            let optionClass = 'bg-[#9C27B0] text-white p-4 rounded-lg text-center ';
            if (selectedAnswer === option) {
              optionClass += isCorrect ? 'bg-green-600' : 'bg-red-600';
            } else if (isAnswered && isCorrectOption) {
              optionClass += 'bg-green-600';
            } else if (!isAnswered) {
              optionClass += 'hover:bg-purple-700 cursor-pointer';
            } else {
              optionClass += 'bg-gray-400 cursor-default';
            }

            return (
              <div
                key={index}
                className={`${optionClass} transition duration-300`}
                onClick={() => !isAnswered && handleAnswerSelection(option)}
              >
                <div dangerouslySetInnerHTML={{ __html: option.replace(/\n/g, '<br/>') }} />
              </div>
            );
          })}
        </div>

        {isCorrect !== null && (
          <>
            {isCorrect === false && selectedAnswer === null && (
              <div className="mt-2 text-red-600 font-semibold text-center">
                Failed: No answer given before timeout.
              </div>
            )}
            <div className="flex justify-center mt-6">
              {currentQuestionIndex < quizData.questions.length - 1 ? (
                <button 
                  className="bg-[#9C27B0] text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300" 
                  onClick={handleNextQuestion}
                >
                  Next
                </button>
              ) : (
                <button 
                  className="bg-[#9C27B0] text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300" 
                  onClick={handleExitQuiz}
                >
                  Finish Quiz
                </button>
              )}
            </div>
            {explanationHtml && explanationHtml.length > 0 && (
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-6">
                <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
              </div>
            )}
            {question.url && (
              <div className="text-center mt-4">
                <a 
                  href={question.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-600 underline"
                >
                  {question.url.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
          </>
        )}
      </div>

      {tagFilter && (
        <div className="mt-2 mb-4 text-sm font-semibold text-purple-900">
          Topic: {tagFilter}
        </div>
      )}
    </div>
  );
}

export default TriviaScreen;
