// QuizLeaderboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { firestore } from './firebase'; // Adjust the path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from './LoadingSpinner'; // Ensure you have a LoadingSpinner component
import Navbar from './Navbar';

const QuizLeaderboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Extract categoryName from route state or query params
    if (location.state && location.state.categoryName) {
      setCategoryName(location.state.categoryName);
      fetchLeaderboardData(location.state.categoryName);
    } else {
      console.error('Category name is missing.');
      navigate('/landing'); // Redirect to a safe page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const fetchLeaderboardData = async (category) => {
    try {
      let categoryCollectionName = `trivia${category.replace(/\s+/g, '')}`;
      console.log(`fetchLeaderboardData: categoryCollectionName: ${categoryCollectionName}`);

      // Adjust collection names based on specific categories
      if (categoryCollectionName === 'triviaRandomTrivia') {
        categoryCollectionName = 'randomQuizAnyCategory10Timed';
      } else if (
        categoryCollectionName === 'triviadailyTrivia' ||
        categoryCollectionName === 'triviaDailyTrivia'
      ) {
        categoryCollectionName = 'dailyTrivia';
        console.log(`Adjusted categoryCollectionName: ${categoryCollectionName}`);
      } else if (categoryCollectionName === 'triviarandomQuizAnyCategory10Timed') {
        categoryCollectionName = 'randomQuizAnyCategory10Timed';
        console.log(`Adjusted categoryCollectionName: ${categoryCollectionName}`);
      }

      const q = query(
        collection(firestore, categoryCollectionName),
        orderBy('score', 'desc'),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLeaderboardData(data);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderItem = (item, index) => (
    <div
      key={item.id}
      className="flex justify-between items-center p-4 bg-white rounded-lg shadow-md mb-2"
    >
      <div className="flex items-center">
        <span className="font-bold mr-2">{index + 1}.</span>
        <span className="text-lg">{item.screenName || 'Anonymous'}</span>
      </div>
      <div className="text-lg font-semibold">{item.score}</div>
    </div>
  );

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="leaderboard-page min-h-screen bg-gray-100 flex flex-col">
      <Navbar title={`${categoryName} Leaderboard`} leftIcon={<FontAwesomeIcon icon={faArrowLeft} />} onLeftIconClick={handleGoBack} />

      {/* Main Content */}
      <div className="flex-1 p-6">
        <div className="max-w-2xl mx-auto">
          {leaderboardData.length === 0 ? (
            <p className="text-center text-gray-600">No data available.</p>
          ) : (
            leaderboardData.map((item, index) => renderItem(item, index))
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizLeaderboard;
