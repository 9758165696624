// useDailyQuiz.js
import { useState, useEffect } from 'react';
import { fnv1aHash } from '../quizUtils';
import { auth, firestore } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

const useDailyQuiz = (quizzesData) => {
  const [dailyQuiz, setDailyQuiz] = useState(null);
  const [streakCount, setStreakCount] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDailyQuiz = async () => {
      try {
        const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD
        const hash = fnv1aHash(today);
        const allQuizzes = quizzesData.sections.flatMap((section) =>
          section.quizzes.map((quiz) => ({ ...quiz, categoryName: section.name }))
        );
        const index = hash % allQuizzes.length;
        const todayQuiz = allQuizzes[index];

        if (todayQuiz) {
          setDailyQuiz(todayQuiz);
        } else {
          console.error('No quiz found for today.');
        }

        await fetchQuizStatusAndStreak(today);
      } catch (error) {
        console.error('Error fetching daily quiz:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchQuizStatusAndStreak = async (today) => {
      const user = auth.currentUser;
      if (!user) {
        // User is not logged in; handle accordingly
        setStreakCount(0);
        setQuizCompleted(false);
        return;
      }

      try {
        // Fetch quiz completion status
        const quizStatusRef = doc(firestore, 'users', user.uid, 'dailyQuizStatus', today);
        const quizStatusSnap = await getDoc(quizStatusRef);
        if (quizStatusSnap.exists()) {
          const data = quizStatusSnap.data();
          setQuizCompleted(data.completed || false);
        } else {
          setQuizCompleted(false);
        }

        // Fetch streak count
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setStreakCount(userData.streakCount || 0);
        } else {
          setStreakCount(0);
        }
      } catch (error) {
        console.error('Error fetching quiz status and streak count:', error);
      }
    };

    fetchDailyQuiz();
  }, [quizzesData]);

  return { dailyQuiz, streakCount, quizCompleted, loading };
};

export default useDailyQuiz;
