// AssignAdmin.js
import React, { useState } from 'react';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';

const AssignAdmin = () => {
  const [uid, setUid] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleAssignAdmin = async () => {
    const addAdminClaim = httpsCallable(functions, 'addAdminClaim');
    try {
      const result = await addAdminClaim({ uid });
      if (result.data.error) {
        setError(result.data.error);
        setMessage('');
      } else {
        setMessage(result.data.message);
        setError('');
      }
    } catch (err) {
      console.error('Error calling addAdminClaim:', err);
      setError('An error occurred while assigning admin role.');
      setMessage('');
    }
  };

  return (
    <div>
      <h2>Assign Admin Role</h2>
      <input
        type="text"
        placeholder="User UID"
        value={uid}
        onChange={(e) => setUid(e.target.value)}
      />
      <button onClick={handleAssignAdmin}>Assign Admin</button>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default AssignAdmin;
