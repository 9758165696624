import React, { useEffect, useState, useRef, useCallback } from 'react';
import { auth, firestore } from './firebase';
import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import Navbar from './Navbar'; 
import debounce from 'lodash.debounce';

function UserScores() {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null); // For pagination
  const [hasMore, setHasMore] = useState(true); // Indicates if more scores can be fetched
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const PAGE_SIZE = 10; // Number of scores per fetch, adjust as needed

  // Fetch user scores with pagination
  const fetchScores = useCallback(async () => {
    if (loading || !hasMore) {
      console.log('Fetch aborted: loading or no more scores');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const scoresRef = collection(firestore, 'userScores');
      let q;

      if (lastDoc) {
        q = query(
          scoresRef,
          where('userId', '==', auth.currentUser.uid),
          orderBy('timestamp', 'desc'),
          startAfter(lastDoc),
          limit(PAGE_SIZE)
        );
      } else {
        q = query(
          scoresRef,
          where('userId', '==', auth.currentUser.uid),
          orderBy('timestamp', 'desc'),
          limit(PAGE_SIZE)
        );
      }

      const snapshot = await getDocs(q);
      console.log('Fetched', snapshot.size, 'scores');

      if (snapshot.empty) {
        setHasMore(false);
      } else {
        const fetchedScores = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setScores((prevScores) => [...prevScores, ...fetchedScores]);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

        if (snapshot.size < PAGE_SIZE) {
          setHasMore(false);
        }
      }
    } catch (err) {
      console.error('Error fetching scores:', err);
      setError('Failed to load scores. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, lastDoc]);

  // Initial fetch and when lastDoc changes
  useEffect(() => {
    fetchScores();
  }, [fetchScores]);

  // Infinite Scroll Handler
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (loading || !hasMore) return;

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.offsetHeight;

      // Trigger fetch when user is within 500px of the bottom
      if (windowHeight + scrollTop >= fullHeight - 500) {
        fetchScores();
      }
    }, 200); // Adjust debounce delay as needed

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [loading, hasMore, fetchScores]);

  // Handle quiz item click
  const handleQuizClick = (quizId) => {
    navigate(`/quizpage?id=${quizId}`);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  if (loading && scores.length === 0) {
    // Show loading spinner only when initially loading
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <Navbar title="Your Scores" leftIcon="arrow-left" onLeftIconClick={handleGoBack} />

      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Your Quiz Scores</h1>
        <ul className="quiz-list">
          {scores.map((score) => (
            <li
              key={score.id}
              className="quiz-item bg-white p-4 mb-4 rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 transition-all"
              onClick={() => handleQuizClick(score.quizId)}
            >
              <h3 className="text-lg font-semibold mb-2">
                {score.quizName.replace(/_/g, ' ')}
              </h3>
              <p className="mb-2">
                <strong>Score:</strong> {score.score}
              </p>
              <p className="text-xs text-gray-500">
                {new Date(score.timestamp.seconds * 1000).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: '2-digit',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </p>
            </li>
          ))}
        </ul>
        {loading && scores.length > 0 && <LoadingSpinner />}
        {error && <p className="text-red-500 text-center mt-4">{error}</p>}
        {!hasMore && scores.length > 0 && (
          <p className="text-center mt-4">You've reached the end of your scores.</p>
        )}
      </div>
    </div>
  );
}

export default UserScores;


