// Navbar.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ title, leftIcon, onLeftIconClick }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/landing');
  };

  return (
    <nav
      className="w-full text-white p-4 flex justify-between items-center"
      style={{ backgroundColor: '#9C27B0' }}
    >
      <div className="flex items-center">
        {leftIcon === 'home' ? (
          <FontAwesomeIcon
            icon={faHome}
            className="text-white text-2xl cursor-pointer"
            onClick={handleHomeClick}
            aria-label="Home"
          />
        ) : (
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-white text-2xl cursor-pointer"
            onClick={onLeftIconClick}
            aria-label="Back"
          />
        )}
      </div>
      <h1 className="text-xl font-bold">{title}</h1>
      <div></div>
    </nav>
  );
};

export default Navbar;




/*
// This is how you do the home button with the navbar:
  <Navbar
        title="Promote Your Quiz"
        leftIcon="home" // Use the home button instead of the back arrow
        />

// This is how do a back button with the navbar
<Navbar
title="Promote Your Quiz"
leftIcon="back"
onLeftIconClick={handleBackToQuiz}
/>

*/