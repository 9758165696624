// components/AlertMessageModal.js

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const AlertMessageModal = ({ isOpen, onClose, title, message, buttons }) => {
  // Close modal on Esc key press
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    } else {
      window.removeEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        {title && <h2 className="text-2xl font-semibold mb-4">{title}</h2>}
        {message && <p className="text-gray-700 mb-6">{message}</p>}
        <div className="flex justify-end space-x-4">
          {buttons && buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={`font-bold py-2 px-4 rounded ${
                button.variant === 'primary'
                  ? 'bg-blue-500 hover:bg-blue-700 text-white'
                  : 'bg-gray-300 hover:bg-gray-400 text-gray-800'
              }`}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

AlertMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.oneOf(['primary', 'secondary']),
    })
  ).isRequired,
};

AlertMessageModal.defaultProps = {
  title: '',
};

export default AlertMessageModal;

