// contexts/AlertContext.js

import React, { createContext, useContext, useState } from 'react';
import AlertMessageModal from '../components/AlertMessageModal';
import PropTypes from 'prop-types';

const AlertContext = createContext();

export const useAlert = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
  const [alertModal, setAlertModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    buttons: [],
  });

  const openAlert = (title, message, buttons) => {
    setAlertModal({
      isOpen: true,
      title,
      message,
      buttons,
    });
  };

  const closeAlert = () => {
    setAlertModal({
      isOpen: false,
      title: '',
      message: '',
      buttons: [],
    });
  };

  return (
    <AlertContext.Provider value={{ openAlert, closeAlert }}>
      {children}
      <AlertMessageModal
        isOpen={alertModal.isOpen}
        onClose={closeAlert}
        title={alertModal.title}
        message={alertModal.message}
        buttons={alertModal.buttons}
      />
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
