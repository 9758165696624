// FinalLearningPlan.js
import React from 'react';
import PropTypes from 'prop-types';
import 'katex/dist/katex.min.css';
import katex from 'katex';

// Helper function to capitalize strings
const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Helper function to format content
const formatContent = (content) => {
    let formatted = content.replace(/\n/g, '<br/>');
  
    // Render inline math: \( ... \)
    formatted = formatted.replace(/\\\((.+?)\\\)/g, (match, p1) => {
      try {
        return katex.renderToString(p1, {
          throwOnError: false,
        });
      } catch (err) {
        console.error('KaTeX render error:', err);
        return match; // Return the original match if rendering fails
      }
    });
  
    // Render display math: \[ ... \] or $$ ... $$
    formatted = formatted.replace(/\\\[(.+?)\\\]/g, (match, p1) => {
      try {
        return katex.renderToString(p1, {
          displayMode: true,
          throwOnError: false,
        });
      } catch (err) {
        console.error('KaTeX render error:', err);
        return match;
      }
    });
  
    formatted = formatted.replace(/\$\$(.+?)\$\$/g, (match, p1) => {
      try {
        return katex.renderToString(p1, {
          displayMode: true,
          throwOnError: false,
        });
      } catch (err) {
        console.error('KaTeX render error:', err);
        return match;
      }
    });
  
    return formatted;
  };
  

const FinalLearningPlan = ({ finalLearningPlan }) => {
  const renderFinalLearningPlan = () => {
    if (!Array.isArray(finalLearningPlan) || finalLearningPlan.length === 0) {
      return <p>No final learning plan available.</p>;
    }

    const renderContent = (content) => {
      if (Array.isArray(content)) {
        return (
          <div className="ml-6 mb-2">
            {content.map((item, idx) => (
              typeof item === 'object' && item !== null ? (
                <span
                  key={idx}
                  className="inline-block mr-4 bg-white p-2 rounded-lg border border-gray-200"
                >
                  {renderContent(item)}
                </span>
              ) : (
                <span
                  key={idx}
                  className="inline-block mr-4 bg-white p-2 rounded-lg border border-gray-200 text-gray-700"
                  dangerouslySetInnerHTML={{ __html: formatContent(String(item)) }}
                />
              )
            ))}
          </div>
        );
      } else if (typeof content === 'object' && content !== null) {
        return Object.entries(content).map(([key, value], idx) => {
          // Check if the key matches the pattern for a number followed by a colon
          if (/^\d+:$/.test(key)) {
            return null; // Skip rendering this key-value pair
          }

          return (
            <div key={idx} className="ml-4 mb-2">
              <span className="font-semibold text-gray-800">
                {capitalize(key.replace(/_/g, ' '))}:
              </span>
              {typeof value === 'object' ? (
                renderContent(value)
              ) : (
                <span
                  className="ml-2 text-gray-700"
                  dangerouslySetInnerHTML={{ __html: formatContent(String(value)) }}
                />
              )}
            </div>
          );
        });
      } else {
        return (
          <span
            className="text-gray-700"
            dangerouslySetInnerHTML={{ __html: formatContent(String(content)) }}
          />
        );
      }
    };

    return finalLearningPlan.map((item, index) => (
      <div
        key={index}
        className="mb-6 p-6 border-l-4 border-blue-500 bg-gray-50 rounded-lg shadow-lg text-left w-full max-w-6xl"
      >
        <h3 className="text-2xl font-bold mb-4">{capitalize(item.milestone)}</h3>
        {item.subCategory && (
          <h4 className="text-xl font-semibold mb-2">
            {capitalize(item.subCategory)}
          </h4>
        )}
        {item.topic && (
          <p
            className="text-lg font-semibold text-gray-800 mb-2"
            dangerouslySetInnerHTML={{ __html: `Topic: ${formatContent(capitalize(item.topic))}` }}
          />
        )}
        {item.description && (
          <p
            className="text-base text-gray-700 mb-4"
            dangerouslySetInnerHTML={{ __html: formatContent(item.description) }}
          />
        )}

        {item.learningContent && (
          <div className="mt-4 text-base text-gray-700">
            {Object.entries(item.learningContent).map(([key, value], idx) => (
              <div key={idx} className="mb-6">
                <h5 className="font-bold text-lg mb-2">
                  {capitalize(key.replace(/_/g, ' '))}
                </h5>
                {renderContent(value)}
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return <div>{renderFinalLearningPlan()}</div>;
};

FinalLearningPlan.propTypes = {
  finalLearningPlan: PropTypes.array.isRequired,
};

export default FinalLearningPlan;
