// contexts/AuthModalContext.js

import React, { createContext, useContext, useState } from 'react';
import AuthOptionsModal from '../components/AuthOptionsModal';

const AuthModalContext = createContext();

export const useAuthModal = () => {
  return useContext(AuthModalContext);
};

export const AuthModalProvider = ({ children }) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openAuthModal = (msg) => {
    setMessage(msg);
    setIsAuthModalOpen(true);
  };

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
    setMessage('');
  };

  return (
    <AuthModalContext.Provider value={{ openAuthModal, closeAuthModal }}>
      {children}
      <AuthOptionsModal
        isOpen={isAuthModalOpen}
        onClose={closeAuthModal} 
        message={message}
      />
    </AuthModalContext.Provider>
  );
};
