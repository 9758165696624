// CuratorPromotion.js
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, firestore, auth } from './firebase'; // Ensure firestore and auth are exported from './firebase'
import { doc, getDoc } from 'firebase/firestore';
import LoadingSpinner from './LoadingSpinner'; // Ensure this component exists
import Navbar from './Navbar'; // Import the Navbar component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function CuratorPromotion() {
  const navigate = useNavigate();
  const location = useLocation();
  const { quizId } = location.state || {};
  const [emails, setEmails] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [loadingQuiz, setLoadingQuiz] = useState(true);
  const [errorQuiz, setErrorQuiz] = useState('');
  const [isCreator, setIsCreator] = useState(false);

  // Initialize Firebase Functions
  const functions = useMemo(() => getFunctions(app, 'us-central1'), []);

  // Fetch Quiz Data to Retrieve Quiz Name and Check if User is Creator
  useEffect(() => {
    const fetchQuizData = async () => {
      if (!quizId) {
        setErrorQuiz('No quiz ID provided.');
        setLoadingQuiz(false);
        return;
      }

      try {
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (quizDocSnap.exists()) {
          const quiz = quizDocSnap.data();
          setQuizData(quiz);

          // Check if the current user is the creator of the quiz
          const currentUser = auth.currentUser;
          if (currentUser && currentUser.email === quiz.userEmail) {
            setIsCreator(true);
          }
        } else {
          setErrorQuiz('Quiz not found.');
        }
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setErrorQuiz('An error occurred while fetching the quiz data.');
      } finally {
        setLoadingQuiz(false);
      }
    };

    fetchQuizData();
  }, [quizId]);

  const sendInvitations = async () => {
    if (!isCreator) {
      alert('Only the quiz creator can send promotion emails.');
      return;
    }

    setSending(true);
    try {
      const sendPromotionEmails = httpsCallable(functions, 'sendPromotionEmails');
      await sendPromotionEmails({
        emails: emails.split(',').map((email) => email.trim()),
        quizId,
        customMessage: message,
      });
      alert('Invitations sent successfully!');
      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error('Error sending invitations:', error);
      alert('Failed to send invitations.');
    } finally {
      setSending(false);
    }
  };

  const handleBackToQuiz = () => {
    navigate(`/quizpage?id=${quizId}`);
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col">
      {/* Navbar */}
      <Navbar title="Promote Your Quiz" leftIcon="home" />

      {/* Back Navigation Button with Quiz Name */}
      {!loadingQuiz && !errorQuiz && quizData && (
        <div className="mt-4 mb-6 flex items-center justify-center">
          <button
            onClick={handleBackToQuiz}
            className="flex items-center bg-white text-purple-900 px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
            title="Back to Quiz Details"
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="text-purple-900 text-2xl cursor-pointer mr-4"
              title="Back"
            />
            <span className="ml-2 text-lg font-semibold">{quizData.quizName.replace(/_/g, ' ')}</span>
          </button>
        </div>
      )}

      {/* Loading Spinner for Quiz Data */}
      {loadingQuiz && (
        <div className="flex-grow flex items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      {/* Display Error Message for Quiz Data */}
      {errorQuiz && (
        <div className="w-full max-w-3xl mt-8 bg-white p-6 rounded-lg shadow-lg text-center mx-auto">
          <p className="text-red-500">{errorQuiz}</p>
        </div>
      )}

      {/* Promotion Form */}
      {!loadingQuiz && !errorQuiz && isCreator && (
        <div className="flex flex-col items-center mt-8 p-4">
          <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">Promote Your Quiz</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="Enter email addresses separated by commas"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              rows={4}
            ></textarea>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="Add a custom message (optional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={4}
            ></textarea>
            <button
              onClick={sendInvitations}
              className={`w-full bg-purple-600 text-white px-6 py-2 rounded-lg font-bold transition-all ${
                sending ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700 hover:shadow-lg'
              }`}
              disabled={sending}
            >
              {sending ? 'Sending...' : 'Send Invitations'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CuratorPromotion;


