// CodeSyndicationScreen.js

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CodeSyndicationScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract quiz ID from query params or pass it via state
  const queryParams = new URLSearchParams(location.search);
  const quizId = queryParams.get('id') || 'YOUR_QUIZ_ID';

  const embedCode = `<iframe src="https://quiznect.com/quizpage?id=${quizId}" width="600" height="800" frameborder="0" allowfullscreen></iframe>`;

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center p-8">
      <h1 className="text-3xl font-bold mb-4">Embed Quiz on Your Website</h1>
      <p className="mb-6">
        Copy and paste the following HTML code into your website to embed the quiz:
      </p>
      <pre className="bg-gray-200 p-4 rounded-lg mb-6 w-full max-w-md overflow-x-auto">
        <code>{embedCode}</code>
      </pre>
      <p className="mb-6">
        You can adjust the <code>width</code> and <code>height</code> attributes to fit your layout.
      </p>
      <button
        onClick={() => navigate(-1)}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Back
      </button>
    </div>
  );
};

export default CodeSyndicationScreen;
