// QuizCategories.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faGlobe, faFlask, faBook, faTrophy, faBrain, faPalette, faGraduationCap, faCode, faCalculator, faSchool, faLanguage, faGavel, faHospital, faCogs, faCity, faUtensils, faBusinessTime, faDumbbell, faBookReader, faFlag, faLaptopCode, faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';

export const categories = [
  { name: 'Entertainment', icon: faFilm },
  { name: 'Geography', icon: faGlobe },
  { name: 'Science', icon: faFlask },
  { name: 'History', icon: faBook },
  { name: 'Sports', icon: faTrophy },
  { name: 'Literature', icon: faBook },
  { name: 'Art', icon: faPalette },
  { name: 'General Knowledge', icon: faBrain },
  { name: 'Software and IT', icon: faCode },
  { name: 'Mathematics', icon: faCalculator },
  { name: 'Standardized Tests', icon: faSchool },
  { name: 'Language Learning', icon: faLanguage },
  { name: 'Law', icon: faGavel },
  { name: 'Medical', icon: faHospital },
  { name: 'Engineering', icon: faCogs },
  { name: 'Architecture', icon: faCity },
  { name: 'Culinary Arts', icon: faUtensils },
  { name: 'Finance and Business', icon: faBusinessTime },
  { name: 'Health and Wellness', icon: faDumbbell },
  { name: 'Education', icon: faBookReader },
  { name: 'Various Subjects', icon: faFlag },
  { name: 'Computer Science & Math Course', icon: faLaptopCode },
];

export const additionalCategories = [
  { name: 'Daily Trivia', icon: faCalendar },
  { name: 'Random Trivia', icon: faClock },
];

export const allCategories = [...additionalCategories, ...categories];
