// QuizzesListLocal.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import quizzesData from './quizdata/quizzes.json';
import QuizzesSection from './QuizzesSection'; // This is similar to SectionList
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faHome } from '@fortawesome/free-solid-svg-icons';
import DailyQuizBanner from './DailyQuizBanner';
import RandomQuizBanner from './RandomQuizBanner'; // Import the new banner component
import useDailyQuiz from './components/useDailyQuiz';
import LoadingSpinner from './LoadingSpinner';

const QuizzesListLocal = () => {
  const navigate = useNavigate();

  // Destructure the loading state from useDailyQuiz
  const { dailyQuiz, streakCount, quizCompleted, loading } = useDailyQuiz(quizzesData);

  const dailyTriviaBannerText = "Today's Daily Quiz";
  const randomTriviaBannerText = "Random Quiz";

  // Random Quiz logic: fetch a random quiz
  const getRandomQuiz = () => {
    const randomIndex = Math.floor(Math.random() * quizzesData.sections.length);
    const randomSection = quizzesData.sections[randomIndex];
    const randomQuizIndex = Math.floor(Math.random() * randomSection.quizzes.length);
    return { 
      ...randomSection.quizzes[randomQuizIndex], 
      categoryName: randomSection.name 
    };
  };

  const randomQuiz = getRandomQuiz(); // Generate a random quiz

  const handleQuizLaunch = (quiz) => {
    navigate(`/trivia-screen`, {
      state: {
        quizFile: quiz.jsonFile,
        quizName: quiz.name,
        quizType: 'randomQuiz10Timed',  // or whichever type you intend to use
        categoryName: quiz.categoryName || '',
      },
    });
  };

  const handleHomeNavigation = () => {
    navigate('/landing');
  };

  // Display a loading spinner while data is being fetched
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon 
            icon={faHome} 
            className="text-white text-2xl cursor-pointer" 
            onClick={handleHomeNavigation} 
          />
        </div>
        <h1 className="text-xl font-bold">
          <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
          Free Quizzes
        </h1>
        <div></div>
      </nav>

      {/* Use DailyQuizBanner Component */}
      <div className="w-full max-w-3xl mt-8 mb-8 bg-white p-6 rounded-lg shadow-lg">
        
        {randomQuiz && (
          <RandomQuizBanner
            bannerText={randomTriviaBannerText}
            quiz={randomQuiz}
            streakCount={null} // No streak for random quizzes
            quizCompleted={false} // No quiz completion state needed for random quiz
            onQuizStart={() => handleQuizLaunch(randomQuiz)}
            className="random-quiz-banner"
          />
        )}
      </div>

      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg">
        {quizzesData.sections.map((section, index) => {
          // Exclude specific sections if needed
          if (section.name === "Computer Science & Math Course") {
            return null;
          } else {
            return (
              <QuizzesSection
                key={index}
                section={section}
                onQuizSelect={handleQuizLaunch}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default QuizzesListLocal;


/*  DailyQuiz:

{dailyQuiz && (
          <DailyQuizBanner
            bannerText={dailyTriviaBannerText}
            quiz={dailyQuiz}
            streakCount={streakCount}
            quizCompleted={quizCompleted}
            onQuizStart={() => handleQuizLaunch(dailyQuiz)}
          />
        )}

        */