import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { firestore, auth } from './firebase';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';

const QuizCurator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const quizId = location.state?.quizId || null; // Get quizId from navigation state
  const [quizData, setQuizData] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false); // Loading state for deletion

  // State variables for editable fields
  const [isPublic, setIsPublic] = useState(false);
  const [curratorInfo, setCurratorInfo] = useState('');

  // Function to desanitize the quiz name (replace underscores with spaces)
  const desanitizeQuizName = (quizName) => {
    return quizName.replace(/_/g, ' ');
  };

  useEffect(() => {
    const fetchQuizData = async () => {
      if (quizId) {
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (quizDocSnap.exists()) {
          const quiz = quizDocSnap.data();
          setQuizData(quiz);

          // Set the initial state for editable fields
          setIsPublic(quiz.isPublic || false);
          setCurratorInfo(quiz.curratorInfo || '');

          // Check if the current user is the creator of the quiz
          const currentUser = auth.currentUser;
          if (currentUser && currentUser.email === quiz.userEmail) {
            setIsCreator(true);
          }
        } else {
          setErrorMessage('Quiz not found.');
        }
      } else {
        setErrorMessage('No quiz ID provided.');
      }
    };

    fetchQuizData();
  }, [quizId]);

  // Function to handle back navigation to the quiz page
  const handleGoBack = () => {
    if (quizId) {
      navigate(`/quizpage?id=${quizId}`);
    } else {
      navigate('/landing');
    }
  };

  const handleUpdateQuiz = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      if (quizId) {
        // Update existing quiz
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);

        // Build changes for metadata
        const quizInfo = {
          isPublic: isPublic,
          curratorInfo: curratorInfo,
        };

        await setDoc(quizDocRef, quizInfo, { merge: true });
        console.log('Quiz successfully updated');
      }

      navigate(`/quizpage?id=${quizId}`);
    } catch (error) {
      console.error('Error updating quiz:', error);
      setErrorMessage('Failed to save the quiz. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteQuiz = async () => {
    if (!quizId) return; // No quiz to delete
  
    // Show confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this quiz? This action cannot be undone."
    );
  
    if (!confirmDelete) return; // Exit if user cancels
  
    setDeleteLoading(true);
  
    try {
      await deleteDoc(doc(firestore, 'quizzesMetadata', quizId));
      console.log('Quiz successfully deleted');
      navigate('/landing'); // Navigate back to landing page after deletion
    } catch (error) {
      console.error('Error deleting quiz:', error);
      setErrorMessage('Failed to delete the quiz. Please try again.');
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEditQuiz = async () => {
    if (!quizId) return; // No quiz to edit
    navigate('/quiz-editor', { state: { quizId } }); // Pass quizId via state
  };
  

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav
        className="w-full text-white p-4 flex justify-between items-center"
        style={{ backgroundColor: '#9C27B0' }}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-white text-2xl cursor-pointer"
            onClick={handleGoBack} // Back to quiz page
          />
        </div>
        <h1 className="text-xl font-bold">Quiz Curator</h1>
        <div></div>
      </nav>

      {quizData && (
        <div className="relative mb-12 mt-8 w-full max-w-3xl">
          <h2 className="text-4xl font-bold text-gray-900 bg-gray-200 p-6 rounded-lg shadow-lg text-center">
            {desanitizeQuizName(quizData.quizName)}
          </h2>
        </div>
      )}

      {isCreator ? (
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg text-left">
          {/* Update Quiz Button */}
          <button
            onClick={handleEditQuiz}
            className={`w-full bg-blue-700 text-white p-4 rounded-lg font-bold transition-all ${
              loading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:bg-blue-700'
            }`}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Edit Quiz Contents'}
          </button>
          

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Curator Info</label>
            <textarea
              value={curratorInfo}
              onChange={(e) => setCurratorInfo(e.target.value)}
              maxLength={256}
              className="w-full p-3 border rounded-lg"
              placeholder="Enter curator info (max 256 characters)"
            />
            <p className="text-sm text-gray-500">{curratorInfo.length}/256 characters</p>
          </div>

          

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Created At</label>
            <p className="text-gray-700">
              {quizData.createdAt}
            </p>
          </div>

          {/* Update Quiz Button */}
          <button
            onClick={handleUpdateQuiz}
            className={`w-full bg-[#9C27B0] text-white p-4 rounded-lg font-bold transition-all ${
              loading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:bg-purple-700'
            }`}
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Update Quiz'}
          </button>

          {/* Delete Quiz Button  */}
          <button
            onClick={handleDeleteQuiz}
            className={`w-full bg-red-600 text-white p-4 rounded-lg font-bold mt-4 transition-all ${
              deleteLoading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:bg-red-700'
            }`}
            disabled={deleteLoading}
          >
            {deleteLoading ? 'Deleting...' : 'Delete Quiz'}
          </button>

          {errorMessage && <p className="text-red-600 mt-4">{errorMessage}</p>}
        </div>
      ) : (
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg text-center mt-8">
          <p className="text-lg font-medium text-gray-700">
            You are not authorized to edit this quiz.
          </p>
        </div>
      )}
    </div>
  );
};

export default QuizCurator;


/*

<h3 className="text-2xl font-semibold mb-4">Edit Quiz</h3>

// public box
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Public Quiz</label>
            <input
              type="checkbox"
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              className="h-5 w-5 text-purple-600"
            />
          </div>
*/