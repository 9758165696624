// DisclaimerPage.js
import React from 'react';
import Navbar from './Navbar'; 
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; 

function DisclaimerPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="min-h-screen mt-4 p-6 bg-white text-black">
      <Navbar title="Policy" leftIcon={<FaArrowLeft />} onLeftIconClick={handleGoBack} />

      <div className="max-w-3xl mx-auto">

        {/* Disclaimer Section */}
        <section className="mb-8 mt-6 ">
          <h2 className="text-2xl font-bold mb-4">Disclaimer</h2>
          <p className="mb-4">
            <strong>AI-Generated Content Notice</strong>
          </p>
          <p className="mb-4">
            The quiz content provided by Quiznect is generated by artificial intelligence (AI). While we strive to provide accurate and up-to-date content, we cannot guarantee the accuracy, completeness, or reliability of any information presented in the app. The material is intended for fun, educational, and informational purposes only and should not be relied upon as a sole source of information. Users should not rely on Quiznect for authoritative or factual information and should independently verify any information that they intend to rely upon for practical purposes. Quiznect disclaims all liability for any inaccuracies or omissions in the content.
          </p>
        </section>

        {/* Privacy Policy Section */}
        <section>
          <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
          <h3 className="text-xl font-bold mb-2">1. Introduction</h3>
          <p className="mb-4">
            Quiznect ("we," "us," or "our") is committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your information when you use our application ("App") and website ("Site").
          </p>

          <h3 className="text-xl font-bold mb-2">2. Information We Collect</h3>
          <h4 className="text-lg font-bold mb-1">2.1 Personal Information:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Account Information:</strong> Email address, screen name, and any other information you provide during account creation.
            </li>
            <li>
              <strong>User Content:</strong> Any content you generate within the app, such as quiz preferences and custom questions.
            </li>
          </ul>
          <h4 className="text-lg font-bold mb-1">2.2 Usage Data:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Analytics Data:</strong> We use Firebase Analytics and Google Analytics to collect anonymized usage data to help us improve the App.
            </li>
            <li>
              <strong>Device Information:</strong> Information about the device you use, including device type, operating system, and unique device identifiers.
            </li>
          </ul>
          <h4 className="text-lg font-bold mb-1">2.3 Third-Party Services:</h4>
          <p className="mb-4">
            Currently we do not use any third party ad services, but at some point, we may need to integrate third-party services to display ads. These services may collect and use data according to their own privacy policies. Users have the option to opt in or out of tracking consent related to these services.
          </p>

          <h3 className="text-xl font-bold mb-2">3. How We Use Your Information</h3>
          <ul className="list-disc list-inside mb-4">
            <li>To provide and maintain our service.</li>
            <li>To improve our services by analyzing usage data.</li>
            <li>To communicate with you regarding updates and support.</li>
            <li>To comply with legal obligations and enforce our terms.</li>
          </ul>

          <h3 className="text-xl font-bold mb-2">4. Data Security</h3>
          <p className="mb-4">
            We implement appropriate security measures to protect your personal data. However, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security.
          </p>

          <h3 className="text-xl font-bold mb-2">5. Children's Privacy</h3>
          <p className="mb-4">
            We comply with the Children's Online Privacy Protection Act (COPPA). We do not knowingly collect or solicit personal information from anyone under the age of 13. If we become aware that we have collected personal information from a child under 13, we will delete that information promptly.
          </p>

          <h3 className="text-xl font-bold mb-2">6. International Data Transfers</h3>
          <p className="mb-4">
            By using Quiznect and providing your information, you authorize us to collect, use, and store your information outside of your home country, including in the United States. Data privacy laws vary across jurisdictions, and different laws may be applicable to your data depending on where it is processed, stored, or used.
          </p>

          <h3 className="text-xl font-bold mb-2">7. Compliance with Laws</h3>
          <p className="mb-4">
            <strong>California Online Privacy Protection Act (CalOPPA):</strong> We comply with CalOPPA and will not distribute your personal information to outside parties without your consent.
          </p>
          <p className="mb-4">
            <strong>General Data Protection Regulation (GDPR):</strong> European Union users have the right to access, rectify, or erase their personal data and restrict or object to its processing.
          </p>

          <h3 className="text-xl font-bold mb-2">8. Third-Party Services</h3>
          <p className="mb-4">
            We may employ third-party companies for analytics, authentication, and advertising. These third parties have access to your personal data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
          </p>

          <h3 className="text-xl font-bold mb-2">9. Changes to This Privacy Policy</h3>
          <p className="mb-4">
            We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
          </p>

          <h3 className="text-xl font-bold mb-2">10. Your Consent</h3>
          <p className="mb-4">
            By using our App or Site, you consent to our Privacy Policy.
          </p>

          <h3 className="text-xl font-bold mb-2">11. Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:quiznect@gmail.com" className="text-blue-500">quiznect@gmail.com</a>.
          </p>
        </section>
      </div>
    </div>
  );
}

export default DisclaimerPage;

