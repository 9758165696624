// QuizzesViewer.js
import React, { useState, useEffect, useRef } from 'react';
import { firestore } from './firebase'; // Ensure Firestore is correctly initialized and exported
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
  FieldPath,
} from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner'; // Ensure this component exists
import debounce from 'lodash.debounce';

function QuizzesViewer() {
  // State Variables
  const [quizzes, setQuizzes] = useState([]);
  const [inputValue, setInputValue] = useState(''); // Controlled input value
  const [searchTerm, setSearchTerm] = useState(''); // Debounced search term
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null); // For pagination
  const [hasMore, setHasMore] = useState(true); // Indicates if more quizzes can be fetched
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const PAGE_SIZE = 20; // Number of quizzes per fetch

  // Ref for the debounce function to persist across renders
  const debouncedSetSearchTerm = useRef(
    debounce((value) => {
      console.log('Debounced searchTerm:', value);
      setSearchTerm(value);
    }, 500) // Adjust debounce delay as needed
  ).current;

  // Handle input changes with debounce
  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log('Input value:', value);
    setInputValue(value);
    debouncedSetSearchTerm.cancel(); // Cancel any pending debounce calls
    debouncedSetSearchTerm(value.trim()); // Trim spaces and set search term
  };

  // Clear search term and reset states
  const clearSearch = () => {
    console.log('Clearing search term');
    setInputValue('');
    debouncedSetSearchTerm.cancel();
    setSearchTerm('');
  };

  // Function to fetch the initial set of quizzes (new search or initial load)
  const fetchInitialQuizzes = async () => {
    console.log('Fetching initial quizzes');
    setLoading(true);
    setError(null);

    try {
      const quizzesRef = collection(firestore, 'quizzesMetadata');
      let q;

      if (searchTerm) {
        console.log('Search mode fetch for:', searchTerm.toLowerCase());
        q = query(
          quizzesRef,
          where('searchKeywords', 'array-contains', searchTerm.toLowerCase()),
          orderBy('quizId', 'desc'),
          limit(PAGE_SIZE)
        );
      } else {
        console.log('Normal mode fetch');
        q = query(
          quizzesRef,
          orderBy('quizId', 'desc'),
          limit(PAGE_SIZE)
        );
      }

      console.log('Built query:', q);

      const snapshot = await getDocs(q);
      console.log('Fetched', snapshot.size, 'quizzes');

      if (snapshot.empty) {
        console.log('No quizzes found');
        setHasMore(false);
      } else {
        const fetchedQuizzes = snapshot.docs.map((doc) => doc.data());
        console.log('Fetched quizzes:', fetchedQuizzes);

        setQuizzes(fetchedQuizzes);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

        if (snapshot.size < PAGE_SIZE) {
          console.log('Reached end of quizzes');
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      setError('Failed to load quizzes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch more quizzes (pagination)
  const fetchMoreQuizzes = async () => {
    console.log('Fetching more quizzes');
    if (loading || !hasMore) {
      console.log('fetchMoreQuizzes aborted: loading or no more quizzes');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const quizzesRef = collection(firestore, 'quizzesMetadata');
      let q;

      if (searchTerm) {
        console.log('Search mode fetch for:', searchTerm.toLowerCase());
        q = query(
          quizzesRef,
          where('searchKeywords', 'array-contains', searchTerm.toLowerCase()),
          orderBy('quizId', 'desc'),
          startAfter(lastDoc),
          limit(PAGE_SIZE)
        );
      } else {
        console.log('Normal mode fetch');
        q = query(
          quizzesRef,
          orderBy('quizId', 'desc'),
          startAfter(lastDoc),
          limit(PAGE_SIZE)
        );
      }

      console.log('Built query:', q);

      const snapshot = await getDocs(q);
      console.log('Fetched', snapshot.size, 'quizzes');

      if (snapshot.empty) {
        console.log('No more quizzes to fetch');
        setHasMore(false);
      } else {
        const fetchedQuizzes = snapshot.docs.map((doc) => doc.data());
        console.log('Fetched quizzes:', fetchedQuizzes);

        setQuizzes((prevQuizzes) => [...prevQuizzes, ...fetchedQuizzes]);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

        if (snapshot.size < PAGE_SIZE) {
          console.log('Reached end of quizzes');
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error('Error fetching more quizzes:', error);
      setError('Failed to load more quizzes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // useEffect to fetch initial quizzes when searchTerm changes
  useEffect(() => {
    console.log('useEffect triggered with searchTerm:', searchTerm);
    setQuizzes([]); // Clear previous quizzes for a new search
    setLastDoc(null);
    setHasMore(true);
    fetchInitialQuizzes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // useEffect to handle infinite scrolling
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (loading || !hasMore) return;

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.offsetHeight;

      // Trigger fetch when user is within 500px of the bottom
      if (windowHeight + scrollTop >= fullHeight - 500) {
        console.log('Scrolled near bottom, fetching more quizzes');
        fetchMoreQuizzes();
      }
    }, 200); // Adjust debounce delay as needed

    window.addEventListener('scroll', handleScroll);
    console.log('Attaching scroll listener');

    return () => {
      window.removeEventListener('scroll', handleScroll);
      console.log('Detaching scroll listener');
      handleScroll.cancel();
    };
  }, [loading, hasMore, searchTerm]);

  // Handle quiz item click
  const handleQuizClick = (quizId) => {
    navigate(`/quizpage?id=${quizId}`);
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav
        className="w-full text-white p-4 flex justify-between items-center"
        style={{ backgroundColor: '#9C27B0' }}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faHome}
            className="text-white text-2xl cursor-pointer"
            onClick={() => navigate('/landing')}
          />
        </div>
        <h1 className="text-xl font-bold">Quiznect - All Quizzes</h1>
        <div></div>
      </nav>

      {/* Search Bar */}
      <div className="w-full max-w-3xl mt-4 mb-4 flex flex-col items-center">
        <input
          type="text"
          placeholder="Search by quiz name or topic..."
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              debouncedSetSearchTerm.flush(); // Immediately trigger search on Enter
            }
          }}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
        />
        {searchTerm && (
          <button
            onClick={clearSearch}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Clear Search
          </button>
        )}
      </div>

      {/* Quizzes List */}
      <div className="w-full max-w-3xl mt-4 mb-8 bg-white p-6 rounded-lg shadow-lg text-left">
        <h2 className="text-2xl font-bold mb-6">Available Quizzes</h2>
        <ul className="quiz-list">
          {quizzes.map((quiz) => (
            <li
              key={quiz.quizId}
              className="quiz-item bg-white p-6 mb-6 rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 transition-all"
              onClick={() => handleQuizClick(quiz.quizId)}
            >
              <h3 className="text-xl font-semibold mb-2">
                {quiz.quizName.replace(/_/g, ' ')}
              </h3>
              <p className="mb-2">
                <strong>Topics:</strong> {quiz.tags.join(', ')}
              </p>
            </li>
          ))}
        </ul>
        {loading && <LoadingSpinner />}
        {error && <p className="text-red-500 text-center mt-4">{error}</p>}
        {!loading && quizzes.length === 0 && !error && (
          <p className="text-center mt-4">No quizzes found matching your search.</p>
        )}
      </div>
    </div>
  );
}

export default QuizzesViewer;
