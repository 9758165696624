// RandomQuizBanner.js
import React from 'react';

const RandomQuizBanner = ({ bannerText, quiz, quizCompleted, onQuizStart }) => {
  if (!quiz) return null;

  return (
    <div className="bg-white text-[#9C27B0] p-4 mt-2 mb-1 text-center rounded-lg shadow-md">
      <h2 className="text-lg font-bold mb-3">{bannerText}</h2>
      
      <button
        className={`${
          quizCompleted
            ? 'bg-gray-500 text-white cursor-not-allowed'
            : 'bg-[#841584] text-white hover:bg-[#7b1fa2] cursor-pointer'
        } py-3 px-3 rounded-md font-bold text-base mt-0.5 mb-2 transition-colors duration-200`}
        onClick={onQuizStart}
        disabled={quizCompleted}
      >
        {quizCompleted ? 'Quiz Completed' : 'Start Random Quiz'}
      </button>
    </div>
  );
};

export default RandomQuizBanner;
