// src/QuiznectAdmin.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, analytics } from './firebase'; // Adjust the path as needed
import { collection, addDoc, getDocs, doc, setDoc, getDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEdit, faTrash, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { logEvent } from 'firebase/analytics';

function QuiznectAdmin() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);
  const [newPromo, setNewPromo] = useState({
    code: '',
    isActive: true,
    maxRedemptions: 1,
    expirationDate: '',
    benefitType: 'freeQuiz',
    benefitValue: 1,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Check if the current user is the admin
  useEffect(() => {
    const checkAdmin = async () => {
      const user = auth.currentUser;
      if (user) {
        // Refresh token to get latest custom claims
        await user.getIdToken(true);
        const token = await user.getIdTokenResult();
        if (token.claims.admin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
          alert('Access Denied: You are not authorized to view this page.');
          navigate('/'); // Redirect to home or appropriate page
        }
      } else {
        setIsAdmin(false);
        alert('Access Denied: Please log in.');
        navigate('/login'); // Redirect to login page
      }
      setLoading(false);
    };

    checkAdmin();
  }, [navigate]);

  // Fetch existing promo codes
  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const promoCollection = collection(firestore, 'promoCodes');
        const promoSnapshot = await getDocs(promoCollection);
        const promos = promoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPromoCodes(promos);
      } catch (err) {
        console.error('Error fetching promo codes:', err);
        setError('Failed to fetch promo codes.');
      }
    };

    if (isAdmin) {
      fetchPromoCodes();
    }
  }, [isAdmin]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPromo(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle form submission to create a new promo code
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    // Basic validation
    if (!newPromo.code.trim()) {
      setError('Promo code is required.');
      return;
    }
    if (!newPromo.expirationDate) {
      setError('Expiration date is required.');
      return;
    }
  
    const promoCode = newPromo.code.trim().toUpperCase();
  
    try {
      // Reference with promo code as Document ID
      const promoCodeRef = doc(firestore, 'promoCodes', promoCode);
  
      // Check if promo code already exists to prevent duplicates
      const promoCodeSnap = await getDoc(promoCodeRef);
      if (promoCodeSnap.exists()) {
        setError('Promo code already exists.');
        return;
      }
  
      await setDoc(promoCodeRef, {
        code: promoCode,
        isActive: newPromo.isActive,
        maxRedemptions: parseInt(newPromo.maxRedemptions, 10),
        redemptions: 0,
        expirationDate: new Date(newPromo.expirationDate),
        benefitType: newPromo.benefitType,
        benefitValue: parseInt(newPromo.benefitValue, 10),
        createdAt: serverTimestamp(),
        createdBy: auth.currentUser.uid,
        notes: '', // Optional field for additional info
      });
  
      // Log event
      if (analytics) {
        logEvent(analytics, 'promo_code_created', { code: promoCode, created_by: auth.currentUser.uid });
      }
  
      // Reset form
      setNewPromo({
        code: '',
        isActive: true,
        maxRedemptions: 1,
        expirationDate: '',
        benefitType: 'freeQuiz',
        benefitValue: 1,
      });
  
      // Refresh promo codes list
      const promoSnapshot = await getDocs(collection(firestore, 'promoCodes'));
      const promos = promoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPromoCodes(promos);
    } catch (err) {
      console.error('Error creating promo code:', err);
      setError('Failed to create promo code.');
    }
  };
  

  // Handle activating/deactivating a promo code
  const togglePromoCode = async (id, currentStatus) => {
    try {
      const promoRef = doc(firestore, 'promoCodes', id);
      await updateDoc(promoRef, { isActive: !currentStatus });

      // Log event
      if (analytics) {
        logEvent(analytics, 'promo_code_toggled', { promo_id: id, new_status: !currentStatus });
      }

      // Update local state
      setPromoCodes(prev => prev.map(promo => 
        promo.id === id ? { ...promo, isActive: !currentStatus } : promo
      ));
    } catch (err) {
      console.error('Error toggling promo code status:', err);
      setError('Failed to update promo code status.');
    }
  };

  // Handle deleting a promo code
  const deletePromoCode = async (id) => {
    if (!window.confirm('Are you sure you want to delete this promo code? This action cannot be undone.')) {
      return;
    }

    try {
      const promoRef = doc(firestore, 'promoCodes', id);
      await deleteDoc(promoRef);

      // Log event
      if (analytics) {
        logEvent(analytics, 'promo_code_deleted', { promo_id: id });
      }

      // Update local state
      setPromoCodes(prev => prev.filter(promo => promo.id !== id));
    } catch (err) {
      console.error('Error deleting promo code:', err);
      setError('Failed to delete promo code.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  if (!isAdmin) {
    return null; // Already handled redirection
  }

  // assign-admin



  return (
    <div className="min-h-screen bg-gray-100 p-8">
      {/* Navbar */}
      <nav className="w-full mb-8 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-purple-700">Quiznect Admin Panel</h1>
        <FontAwesomeIcon icon={faHome} className="text-purple-700 text-2xl cursor-pointer" onClick={() => navigate('/landing')} />
      </nav>

      {/* Error Message */}
      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-6">
          {error}
        </div>
      )}

      <button className="bg-[#9C27B0] text-white border-none py-2 px-4 rounded cursor-pointer w-52 mt-2" onClick={() => navigate('/assign-admin')}>
            Assign Admin
        </button>
        
      {/* Create Promo Code Form */}
      <div className="bg-white p-6 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-bold mb-4">Create New Promo Code</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Code */}
          <div>
            <label className="block text-gray-700">Code<span className="text-red-500">*</span></label>
            <input
              type="text"
              name="code"
              value={newPromo.code}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
          </div>

          {/* Is Active */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="isActive"
              checked={newPromo.isActive}
              onChange={handleChange}
              className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-gray-700">Is Active</label>
          </div>

          {/* Max Redemptions */}
          <div>
            <label className="block text-gray-700">Max Redemptions<span className="text-red-500">*</span></label>
            <input
              type="number"
              name="maxRedemptions"
              value={newPromo.maxRedemptions}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              min="1"
              required
            />
          </div>

          {/* Expiration Date */}
          <div>
            <label className="block text-gray-700">Expiration Date<span className="text-red-500">*</span></label>
            <input
              type="date"
              name="expirationDate"
              value={newPromo.expirationDate}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
          </div>

          {/* Benefit Type */}
          <div>
            <label className="block text-gray-700">Benefit Type<span className="text-red-500">*</span></label>
            <select
              name="benefitType"
              value={newPromo.benefitType}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            >
              <option value="freeQuiz">Free Quiz</option>
              <option value="discount">Discount</option>
              {/* Add more benefit types as needed */}
            </select>
          </div>

          {/* Benefit Value */}
          <div>
            <label className="block text-gray-700">Benefit Value<span className="text-red-500">*</span></label>
            <input
              type="number"
              name="benefitValue"
              value={newPromo.benefitValue}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              min="1"
              required
            />
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white p-2 rounded hover:bg-purple-700 transition-colors"
            >
              Create Promo Code
            </button>
          </div>
        </form>
      </div>

      {/* Existing Promo Codes List */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-bold mb-4">Existing Promo Codes</h2>
        {promoCodes.length === 0 ? (
          <p>No promo codes available.</p>
        ) : (
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Code</th>
                <th className="py-2 px-4 border-b">Active</th>
                <th className="py-2 px-4 border-b">Max Redemptions</th>
                <th className="py-2 px-4 border-b">Redemptions</th>
                <th className="py-2 px-4 border-b">Expiration Date</th>
                <th className="py-2 px-4 border-b">Benefit</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {promoCodes.map(promo => (
                <tr key={promo.id}>
                  <td className="py-2 px-4 border-b text-center">{promo.code}</td>
                  <td className="py-2 px-4 border-b text-center">
                    {promo.isActive ? (
                      <FontAwesomeIcon 
                        icon={faToggleOn} 
                        className="text-green-500 cursor-pointer" 
                        onClick={() => togglePromoCode(promo.id, promo.isActive)} 
                      />
                    ) : (
                      <FontAwesomeIcon 
                        icon={faToggleOff} 
                        className="text-gray-500 cursor-pointer" 
                        onClick={() => togglePromoCode(promo.id, promo.isActive)} 
                      />
                    )}
                  </td>
                  <td className="py-2 px-4 border-b text-center">{promo.maxRedemptions}</td>
                  <td className="py-2 px-4 border-b text-center">{promo.redemptions}</td>
                  <td className="py-2 px-4 border-b text-center">
                    {promo.expirationDate.toDate().toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {promo.benefitType} ({promo.benefitValue})
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <FontAwesomeIcon 
                      icon={faTrash} 
                      className="text-red-500 cursor-pointer mr-4" 
                      onClick={() => deletePromoCode(promo.id)} 
                    />
                    {/* Future: Add edit functionality here */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default QuiznectAdmin;
