import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from './firebase';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function TourneyResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const { tournamentId, quizId, quizName } = location.state || {};
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScores = async () => {
      if (tournamentId) {
        try {
          const userScoresRef = collection(firestore, 'userScores');
          const scoresQuery = query(
            userScoresRef,
            where('tournamentId', '==', tournamentId),
            orderBy('score', 'desc'),
            orderBy('timestamp', 'asc') // Earliest submissions first if same score
          );
          const scoresSnapshot = await getDocs(scoresQuery);
          const scoresData = scoresSnapshot.docs.map(doc => doc.data());
          setScores(scoresData);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching tournament results:', error);
        }
      } else {
        console.log('Error no tournamentId');
      }
    };
    fetchScores();
  }, [tournamentId]);

  if (loading) {
    return <div>Loading tournament results...</div>;
  }
  const handleGoBackToQuizPage = () => {
    if (quizId) {
      navigate(`/quizpage?id=${quizId}`);
    } else {
      console.error('quizId is not available');
      alert('Cannot navigate back to Quiz Page. quizId is missing.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon 
            icon={faHome} 
            className="text-white text-2xl cursor-pointer" 
            onClick={() => navigate('/landing')} 
            title="Home"
          />
        </div>
        <h1 className="text-xl font-bold">Tournament Results</h1>
        <div className="flex items-center space-x-4">
          {/* Optionally, other icons */}
        </div>
      </nav>

      {/* Centered Quiz Name */}
      <div className="mt-4 mb-6">
        
        <button 
          onClick={handleGoBackToQuizPage} 
          className="text-2xl font-bold bg-white text-purple-900 px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
          title="Back to Quiz Details"
        >
          {quizName}
        </button>
      </div>

      <div className="w-full max-w-3xl mb-8 bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Leaderboard</h2>
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Rank</th>
              <th className="px-4 py-2">User</th>
              <th className="px-4 py-2">Score</th>
              <th className="px-4 py-2">Time</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score, index) => (
              <tr key={index} className="border-t">
                <td className="px-4 py-2">{index + 1}</td>
                <td className="px-4 py-2">{score.screenName || 'Anonymous'}</td>
                <td className="px-4 py-2">{score.score}</td>
                <td className="px-4 py-2">{new Date(score.timestamp.toDate()).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TourneyResults;

/*
<h2 className="text-2xl font-bold">{quizName}</h2>
*/