// LandingPage.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, analytics } from './firebase';
import { setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import stringSimilarity from 'string-similarity';
import quizzesData from './quizdata/quizzes.json';  
import triviaData from './quizdata/trivia.json';   
import triviaFileMapping from './quizdata/TriviaFileMapping'; 
import bannedWords from './utils/engBadWords.json';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaPen, FaTrash, FaTimesCircle, FaPlus, FaTimes } from 'react-icons/fa'; 
import { faLightbulb, faGraduationCap, faMagnifyingGlass, faUser, faArrowRight, faTrophy } from '@fortawesome/free-solid-svg-icons';  // Importing icons
import DailyQuizBanner from './DailyQuizBanner'; 
import useDailyQuiz from './components/useDailyQuiz'; 
import ScreenNameContext from './ScreenNameContext';
import { logEvent } from 'firebase/analytics'; // Ensure logEvent is imported
import { useAlert } from './contexts/AlertContext';

function LandingPage() {
  const [learningGoal, setLearningGoal] = useState('');
  const [learningGoalInput, setLearningGoalInput] = useState(''); // Separate input state
  const [learningGoalHistory, setLearningGoalHistory] = useState([]); 
  const [user, setUser] = useState(null);
  const { screenName, setScreenName } = useContext(ScreenNameContext);
  const [isSubmitted, setIsSubmitted] = useState(false); // Check if goal was submitted
  const [quizGenerationStatus, setQuizGenerationStatus] = useState('');
  const [quizId, setQuizId] = useState('');
  const { openAlert, closeAlert } = useAlert();
  const textareaRef = useRef(null);
  const navigate = useNavigate();

  const { dailyQuiz, streakCount, quizCompleted, loading } = useDailyQuiz(triviaData);
  const dailyTriviaBannerText = "Today's Daily Quiz";

  // Random Quiz logic: fetch a random quiz
  const getRandomQuiz = () => {
    const randomIndex = Math.floor(Math.random() * triviaData.sections.length);
    const randomSection = quizzesData.sections[randomIndex];
    const randomQuizIndex = Math.floor(Math.random() * randomSection.quizzes.length);
    return { ...randomSection.quizzes[randomQuizIndex], categoryName: randomSection.name };
  };

  const randomQuiz = getRandomQuiz(); // Generate a random quiz

  useEffect(() => {
    // Log page view event
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: '/landing',
        page_title: 'Landing Page',
        page_location: window.location.href,
      });
    }

    // Listen for changes in authentication state
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await fetchUserLearningData(currentUser.uid);
        await fetchUserScreenName(currentUser.uid); 
      } else {
        alert('You must be logged in to set a learning goal.');
        navigate('/');
        
        // Log navigation away due to unauthenticated access
        if (analytics) {
          logEvent(analytics, 'navigate_unauthenticated', { from: '/landing', to: '/' });
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate, analytics]);

  const fetchUserLearningData = async (userUid) => {
    try {
      const userDocRef = doc(firestore, 'learningGoals', userUid);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setLearningGoal(data.learningGoal || '');  // Set saved learning goal from Firestore
        setLearningGoalHistory(data.learningGoalHistory || []); 
        // Set isSubmitted based on whether learningGoal exists
        setIsSubmitted(!!data.learningGoal);

        setQuizGenerationStatus(data.quizGenerationStatus || '');
        setQuizId(data.quizId || '');

        // Log existing learning goal retrieval
        if (analytics) {
          logEvent(analytics, 'learning_goal_retrieved', { learning_goal: data.learningGoal || 'None' });
        }
      } else {
        // Reset if no data exists
        setLearningGoal('');
        setIsSubmitted(false);
      }
    } catch (error) {
      console.error('Error fetching learning goal data:', error);
    }
  };

  const fetchUserScreenName = async (uid) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setScreenName(data.screenName || '');  
        
        // Log screen name retrieval
        if (analytics) {
          logEvent(analytics, 'screen_name_retrieved', { screen_name: data.screenName || 'None' });
        }
      }
    } catch (error) {
      console.error('Error fetching screen name:', error);
    }
  };

  const containsBannedWords = (text) => {
    const textLower = text.toLowerCase();
    const badWordPattern = new RegExp(`\\b(${bannedWords.join('|')})\\b`, 'gi');
    return badWordPattern.test(textLower);
  };

  const handleSubmit = async () => {
    if (!learningGoalInput) {
      openAlert(
        'Missing Input',
        'Please enter a learning goal.',
        [{ label: 'OK', onClick: () => closeAlert(), variant: 'primary' }]
      );
      return;
    }
    
    if (!user) {
      openAlert(
        'Authentication Required',
        'You must be logged in to set a learning goal.',
        [
          { 
            label: 'Log In', 
            onClick: () => { 
              navigate('/', { state: { mode: 'login' } }); 
              closeAlert(); 
            }, 
            variant: 'primary' 
          },
          { 
            label: 'Sign Up', 
            onClick: () => { 
              navigate('/', { state: { mode: 'signup' } }); 
              closeAlert(); 
            }, 
            variant: 'secondary' 
          }
        ]
      );
      return;
    }
      
    if (containsBannedWords(learningGoalInput)) {
      openAlert(
        'Inappropriate Content',
        'Your learning goal contains inappropriate content. Please try again.',
        [{ label: 'OK', onClick: () => closeAlert(), variant: 'primary' }]
      );
      return;
    }
    
    try {
      const learningGoalsRef = doc(firestore, 'learningGoals', user.uid);
      await setDoc(learningGoalsRef, {
        learningGoal: learningGoalInput,
        userEmail: user.email,
        userUid: user.uid,
        userScreenName: screenName || user.displayName,
        timestamp: serverTimestamp(),
        learningPlan: '', // Reset learning plan
        enhancedLearningPlan: '',
        feedback: '', // Reset feedback
        progressPercentage: 0, // Set to 0
        quizGenerationStatus: '', // Reset quiz generation status
        evaluationScore: '',
        triggerEvalGoalAndEnhance: true,
        }, { merge: true });

      const matchedQuizzes = findMatchingQuizzes(learningGoalInput);
      console.log("Matched Quizzes:", matchedQuizzes);

      // Log learning goal submission
      if (analytics) {
        logEvent(analytics, 'learning_goal_submit', { learning_goal: learningGoalInput, matched_quizzes_count: matchedQuizzes.length });
      }

      // Update state variables
      setLearningGoal(learningGoalInput);  // Set the saved goal
      setIsSubmitted(true); // Now mark it as submitted

      navigate('/learning-focus', {
        state: { learningGoal: learningGoalInput, matchedQuizzes }
      });

      // Log navigation to learning focus
      if (analytics) {
        logEvent(analytics, 'navigate_to_learning_focus', { learning_goal: learningGoalInput });
      }

    } catch (error) {
      console.error('Error saving learning goal:', error);
      alert('Failed to save your learning goal. Please try again later.');

      // Log learning goal submission failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_submit_failure', { error_code: error.code, error_message: error.message });
      }
    }
  };

  const handleTriviaExplorer = () => {
    navigate('/trivia'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'trivia_explorer' });
    }
  }
  
  const handleQuizExplorer = () => {
    navigate('/quizzes-free'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'quiz_explorer' });
    }
  }
  
  const handleQuizSearch = () => {
    navigate('/quizzes-viewer'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'quiz_search' });
    }
  }
  
  const handleMyQuizzes = () => {
    navigate('/my-quizzes-viewer'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'my_quizzes' });
    }
  }

  const findMatchingQuizzes = (goal, threshold = 0.42, maxResults = 5) => {
    const allQuizzes = [...quizzesData.sections, ...triviaData.sections];
    const matchingQuizzes = [];

    for (const section of allQuizzes) {
      for (const quiz of section.quizzes) {
        const quizData = triviaFileMapping[quiz.jsonFile];
        
        if (!quizData) {
          continue;
        }

        const quizName = quiz.name.toLowerCase();
        const goalLower = goal.toLowerCase();

        const nameSimilarity = stringSimilarity.compareTwoStrings(quizName, goalLower);
        const metadataSimilarity = quizData.metadata?.quizName 
          ? stringSimilarity.compareTwoStrings(quizData.metadata.quizName.toLowerCase(), goalLower)
          : 0;
        const tagsSimilarity = quizData.questions?.reduce((max, question) => {
          const questionMax = question.tags?.reduce((tagMax, tag) => {
            const tagSimilarity = stringSimilarity.compareTwoStrings(tag.toLowerCase(), goalLower);
            return Math.max(tagMax, tagSimilarity);
          }, 0) || 0;
          return Math.max(max, questionMax);
        }, 0) || 0;

        const bestMatch = Math.max(nameSimilarity, metadataSimilarity, tagsSimilarity);

        if (bestMatch >= threshold) {
          matchingQuizzes.push({
            ...quiz,
            matchScore: bestMatch
          });

          // Log quiz match found
          if (analytics) {
            logEvent(analytics, 'quiz_match_found', { quiz_name: quiz.name, match_score: bestMatch });
          }
        }
      }
    }

    matchingQuizzes.sort((a, b) => b.matchScore - a.matchScore);
    return matchingQuizzes.slice(0, maxResults);
  };

  const handleHistorySelection = (selectedGoal) => {
    navigate('/learning-focus', {
      state: { selectedGoal }, // Passing the selected learning goal as state
    });

    // Log history selection
    if (analytics) {
      logEvent(analytics, 'learning_goal_history_selected', { selected_goal: selectedGoal });
    }
  };

  const removeUndefinedFields = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
  };

  const handleDeleteLearningGoal = async () => {
    if (!window.confirm('Are you sure you want to delete this learning goal?')) {
      return;
    }
  
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently logged in.');
      return;
    }
  
    const learningGoalsRef = doc(firestore, 'learningGoals', currentUser.uid);
  
    try {
      console.log('Fetching document for user:', currentUser.uid);
      const docSnap = await getDoc(learningGoalsRef);
      if (docSnap.exists()) {
        const currentData = docSnap.data();
        const existingHistory = currentData.learningGoalHistory || [];
  
        // Only save the current learning goal into history if it doesn't already exist and is not empty
        const isDuplicate = existingHistory.some((historyItem) =>
          historyItem.learningGoal === currentData.learningGoal &&
          historyItem.learningPlan === currentData.learningPlan
        );
  
        if (!isDuplicate && currentData.learningGoal && currentData.learningGoal !== '') {
          const newHistoryItem = removeUndefinedFields({
            learningGoal: currentData.learningGoal,
            learningPlan: currentData.learningPlan,
            outputFilePath: currentData.outputFilePath,
            quizId: currentData.quizId,
            evaluationScore: currentData.evaluationScore,
            enhancedLearningPlan: currentData.enhancedLearningPlan,
            feedback: currentData.feedback,
            progressPercentage: currentData.progressPercentage,
            quizGenerationStatus: currentData.quizGenerationStatus,
            timestamp: currentData.timestamp || new Date().toISOString(),
          });
  
          const updatedHistory = [...existingHistory, newHistoryItem];
  
          console.log('Updated history:', updatedHistory);
  
          // Update Firestore with the new learning goal and move the old one to history
          await setDoc(
            learningGoalsRef,
            {
              learningGoal: '', // Reset learning goal
              learningPlan: '', // Reset learning plan
              feedback: '',
              progressPercentage: 0, // Set to 0
              quizGenerationStatus: '', // Reset quiz generation status
              outputFilePath: '',
              quizId: '',
              timestamp: '',
              userEmail: '',
              userUid: '',
              userScreenName: '',
              evaluationScore: '',
              enhancedLearningPlan: '',
              learningGoalHistory: updatedHistory, // Add updated history
              triggerEvalGoalAndEnhance: false,
            },
            { merge: true }
          );
  
          console.log('Successfully updated the learning goal and moved it to history.');
  
          // **Update local state variables**
          setLearningGoal('');
          setIsSubmitted(false);
          setQuizGenerationStatus('');
          setQuizId('');
          setLearningGoalHistory(updatedHistory); // Update history in state if necessary
  
          // No need to navigate since we're already on '/landing'
          // navigate('/landing');
  
          // Log learning goal deletion
          if (analytics) {
            logEvent(analytics, 'learning_goal_deleted', { previous_learning_goal: currentData.learningGoal });
          }
        } else {
          console.log('No changes to the learning goal, or duplicate entry found.');
          // Update Firestore with the reset learning goal
          await setDoc(
            learningGoalsRef,
            {
              learningGoal: '', // Reset learning goal
              learningPlan: '', // Reset learning plan
              feedback: '',
              progressPercentage: 0, // Set to 0
              quizGenerationStatus: '', // Reset quiz generation status
              outputFilePath: '',
              quizId: '',
              timestamp: '',
              userEmail: '',
              userUid: '',
              userScreenName: '',
              evaluationScore: '',
              enhancedLearningPlan: '',
              learningGoalHistory: existingHistory, // Keep existing history
              triggerEvalGoalAndEnhance: false,
            },
            { merge: true }
          );
  
          console.log('Successfully updated the learning goal.');
  
          // **Update local state variables**
          setLearningGoal('');
          setIsSubmitted(false);
          setQuizGenerationStatus('');
          setQuizId('');
          setLearningGoalHistory(existingHistory);
  
          // No need to navigate since we're already on '/landing'
          // navigate('/landing');
  
          // Log learning goal deletion
          if (analytics) {
            logEvent(analytics, 'learning_goal_deleted', { previous_learning_goal: currentData.learningGoal });
          }
        }
      } else {
        console.error('Document does not exist for user:', currentUser.uid);
      }
    } catch (error) {
      console.error('Error resetting learning goal:', error);
      alert('Failed to reset your learning goal. Please try again.');
  
      // Log learning goal deletion failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_deletion_failure', { error_code: error.code, error_message: error.message });
      }
    }
  };

  const handleProfileNavigation = () => {
    navigate('/profile');
    // Log profile navigation event if analytics is provided
    if (analytics && logEvent) {
      logEvent(analytics, 'navigate_button_click', { destination: 'profile' });
    }
  };

  const showQuizPage = () => {
    if (quizId) {
      navigate(`/quizpage?id=${quizId}`);
      // Log navigation to quiz page
      if (analytics) {
        logEvent(analytics, 'navigate_to_quiz_page', { quiz_id: quizId });
      }
    } else {
      console.error('quizId is not available');
    }
  };

  const handleLeaderboards = () => {
    navigate(`/leaderboards`);
  };


  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faUser} className="text-white text-2xl" onClick={handleProfileNavigation} />
        </div>

        <h1 className="text-center text-lg font-bold mr-15 ">Quiznect</h1>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faTrophy} className="text-white text-2xl" onClick={handleLeaderboards} />
        </div>
      </nav>

      {/* Conditionally render the banner or the input section */}
      {isSubmitted && learningGoal ? (
         <div className="flex items-center bg-gray-100 px-4 py-2">
         {/* Close Icon */}
         <FaTimes
           style={{ color: '#841584' }}
           className="mx-2 cursor-pointer"
           size={24}
           onClick={() => handleDeleteLearningGoal()}
         />
       
         {/* Learning Focus Box */}
         <div
            className="bg-purple-600 text-white rounded-lg p-4 my-4 flex justify-between items-center w-full max-w-2xl cursor-pointer"
            onClick={() => {
              if (quizGenerationStatus === 'Completed') {
                showQuizPage();
              } else {
                navigate('/learning-focus', { state: { learningGoal } });
              }
              // Log navigation event
              if (analytics) {
                logEvent(analytics, 'navigate_to_learning_focus_click', { learning_goal: learningGoal });
              }
            }}
            style={{ backgroundColor: '#841584', cursor: 'pointer' }}
          >
           <div className="flex items-center">
             {/* Lightbulb Icon */}
             <FontAwesomeIcon icon={faLightbulb} className="text-white text-4xl mr-4" />
             {/* Learning Focus Text */}
             <div>
               <h2 className="text-lg md:text-xl font-bold">Learning Focus: {learningGoal}</h2>
               <p className="text-sm md:text-base">Continue your learning plan based on your goal.</p>
             </div>
           </div>
           {/* Arrow Icon */}
           <FontAwesomeIcon icon={faArrowRight} className="text-white text-4xl ml-4" />
         </div>
         
       </div>       
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission
            handleSubmit(); // Manually handle the submit
          }}
        >
          <div className="bg-gray-100 border-2 border-gray-300 p-3 rounded-xl w-full max-w-xl mb-3 mt-1 text-center">

            <h1 className="text-2xl font-bold">What would you like to learn?</h1>
            <p className="mt-4 text-base">Tell us your learning goal, and we'll create a custom quiz plan just for you.</p>
            <textarea
                placeholder="I want to learn..."
                value={learningGoalInput}
                onChange={(e) => {
                  setLearningGoalInput(e.target.value.slice(0, 200));
                  
                  // Log learning goal input change (input length)
                  if (analytics) {
                    logEvent(analytics, 'learning_goal_input_change', { input_length: e.target.value.length });
                  }
                }}  // Limit to 200 chars
                maxLength={200}
                className="w-full p-2 mt-4 rounded-lg border border-gray-300 text-black resize-none min-h-10"
                ref={textareaRef}
                style={{ overflow: 'hidden' }}
                onInput={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onPaste={(e) => {
                    // Wait for paste to complete, then scroll to bottom
                    setTimeout(() => {
                    if (textareaRef.current) {
                        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
                    }
                    }, 0);
                }}
            />

            {learningGoalInput && learningGoalInput.length > 50 && (
              <p className="text-sm text-gray-500">{learningGoalInput.length}/200 characters</p>
            )}
            

            <button
              type="submit"
              style={{ backgroundColor: '#841584' }}
              className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold"
              onClick={() => {
                // Log submit button click
                if (analytics) {
                  logEvent(analytics, 'submit_learning_goal_click', { learning_goal: learningGoalInput });
                }
              }}
            >
              Submit
            </button>

            {/* Dropdown for previous learning goals */}
            {learningGoalHistory && learningGoalHistory.length > 0 && (
              <div className="mt-4 text-center flex items-center justify-center space-x-4">
                <label className="text-lg font-bold">Learning Goals:</label>
                <select
                  className="w-[240px] p-2 rounded-lg border border-gray-300 text-black"
                  onChange={(e) => {
                    handleHistorySelection(e.target.value);
                    
                    // Log history selection
                    if (analytics) {
                      logEvent(analytics, 'learning_goal_history_select', { selected_goal: e.target.value });
                    }
                  }}
                >
                  <option value="">Select a goal...</option>
                  {learningGoalHistory.map((goal, index) => (
                    <option key={index} value={goal.learningGoal}>
                      {goal.learningGoal}
                    </option>
                  ))}
                </select>
              </div>
            )}

          </div>
        </form>
      )}

      {/* Section: Search & Free trivia and quizzes */}
      <div className="bg-gray-100 border-2 border-gray-300 p-3 rounded-xl w-full max-w-xl mb-3 text-center" style={{ marginTop: -5 }} >
        <p className="text-xl font-bold">Explore Quizzes and Trivia</p>
        <button
          onClick={handleMyQuizzes}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faUser} className="mr-2" /> Your Quizzes
        </button>
        <button
          onClick={handleQuizSearch}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" /> Quiz search
        </button>
        <button
          onClick={handleTriviaExplorer}
          style={{ backgroundColor: '#841584' }} 
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faLightbulb} className="mr-2" /> Trivia
        </button>
        <button
          onClick={handleQuizExplorer}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faGraduationCap} className="mr-2" /> Quizzes
        </button>
      </div>

   
       <button
          onClick={handleProfileNavigation}
          className="flex items-center bg-[#841584] text-white mt-8 mb-2 px-4 py-2 rounded-md hover:bg-[#7b1fa2] transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#9C27B0]"
          type="button"
          aria-label={screenName ? 'Go to Profile Settings' : 'Add a Screen Name'}
        >
          <FontAwesomeIcon 
            icon={faUser} 
            className="text-white text-lg" 
            aria-hidden="true" 
          />
          {screenName ? (
            <p className="m-0 text-sm font-bold ml-2">{screenName}</p>
          ) : (
            <p className="m-0 text-sm font-bold ml-2">Add Screen Name</p>
          )}
        </button>
    </div>
  );
}

export default LandingPage;


/*

  const handleQuizLaunch = (quiz) => {
    // Log quiz start event
    if (analytics) {
      logEvent(analytics, 'quiz_start', { quiz_name: quiz.name, category: quiz.categoryName || 'Unknown' });
    }
    navigate(`/trivia-screen`, {
      state: {
        quizFile: quiz.jsonFile,
        quizName: quiz.name,
        quizType: 'randomQuiz10Timed',  // or whichever type you intend to use
        categoryName: quiz.categoryName || '',
      },
    });
  };

   // Daily Quiz Banner MAYBE LATER -FOR NOW REMOVE!!
   <DailyQuizBanner
   style={{ marginTop: -30 }}
   bannerText={dailyTriviaBannerText}
   quiz={dailyQuiz}
   streakCount={streakCount}
   quizCompleted={quizCompleted}
   onQuizStart={() => {
     handleQuizLaunch(dailyQuiz);
     
     // Log daily quiz start
     if (analytics) {
       logEvent(analytics, 'daily_quiz_start', { streak_count: streakCount });
     }
   }}
   />

{screenName && (
  <span className="ml-2 text-sm cursor-pointer" onClick={() => { 
    navigate('/profile');
    // Log profile navigation
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'profile' });
    }
  }}>
    {screenName}
  </span>
)}
*/


// todo: {/* Plan Section */}
      /*
      <div className="mt-16 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Choose Your Plan</h2>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Monthly Subscription</h3>
          <p className="text-gray-700 mb-4">Get a new quiz generated every month to help you master your chosen topic.</p>
          <p className="text-2xl font-bold">$9.99/month</p>
          <button
            className="mt-4 w-full bg-green-600 text-white p-3 rounded-lg text-base font-bold"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    */