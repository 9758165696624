import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { firestore, auth } from './firebase';
import { collection, doc, addDoc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import ScreenNameContext from './ScreenNameContext';

const TournamentCurator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const quizId = location.state?.quizId || null; // Get quizId from navigation state
  const tournamentId = location.state?.tournamentId || null; // Get tournamentId if editing
  const [quizData, setQuizData] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false); // Loading state for deletion
  const [tourneyInfo, setTourneyInfo] = useState('');
  const { screenName, setScreenName } = useContext(ScreenNameContext);

  
  // Function to desanitize the quiz name (replace underscores with spaces)
  const desanitizeQuizName = (quizName) => {
    return quizName.replace(/_/g, ' ');
  };

  // Convert Firestore UTC timestamp to local time for input fields (in datetime-local format)
  const convertToLocalTime = (date) => {
    const localTime = date instanceof Date ? date : new Date(date.toDate ? date.toDate() : date);
    return localTime.toISOString().slice(0, 16); // Ensure the datetime-local input format (yyyy-MM-ddTHH:mm)
  };

  // Convert local time back to UTC for saving in Firestore
  const convertToUTC = (localDateTimeString) => {
    const localDate = new Date(localDateTimeString);
    return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000); // Ensure UTC
  };

  useEffect(() => {
    const fetchQuizData = async () => {
      if (quizId) {
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (quizDocSnap.exists()) {
          const quiz = quizDocSnap.data();
          setQuizData(quiz);

          // Check if the current user is the creator of the quiz
          const currentUser = auth.currentUser;
          console.log(" fetchQuizData: currentUser.email" + currentUser.email);
          if (currentUser && currentUser.email === quiz.userEmail) {
            setIsCreator(true);
          }
          console.log(" isCreator: " + isCreator);
          
          // If editing a tournament, load tournament data
          if (tournamentId) {
            const tournamentDocRef = doc(firestore, 'tournaments', tournamentId);
            const tournamentDocSnap = await getDoc(tournamentDocRef);
            if (tournamentDocSnap.exists()) {
              const tournament = tournamentDocSnap.data();
              setTourneyInfo(tournament.tourneyInfo || '');
              setStartTime(convertToLocalTime(tournament.startTime)); // Set local time for input
              setEndTime(convertToLocalTime(tournament.endTime)); // Set local time for input
            }
          }
        }
      }
    };

    fetchQuizData();
  }, [quizId, tournamentId]);

  // Function to handle back navigation to the quiz page
  const handleGoBack = () => {
    if (quizId) {
      navigate(`/quizpage?id=${quizId}`);
    }
  };

  const handleCreateOrUpdateTournament = async () => {
    setLoading(true);
    setErrorMessage('');

    const start = new Date(startTime);
    const end = new Date(endTime);
    const now = new Date();

    // Check if the start time is in the past
    if (start < now) {
      setErrorMessage('Start time cannot be in the past.');
      setLoading(false);
      return; // Stop further execution if validation fails
    }

    // Check if the end time is before or equal to the start time
    if (end <= start) {
      setErrorMessage('End time cannot be before or equal to the start time.');
      setLoading(false);
      return; // Stop further execution if validation fails
    }

    // Check if the time delta between start and end is less than 5 minutes
    const timeDeltaMinutes = (end - start) / (1000 * 60); // Time difference in minutes
    if (timeDeltaMinutes < 5) {
      setErrorMessage('End time must be at least 5 minutes after the start time.');
      setLoading(false);
      return; // Stop further execution if validation fails
    }
    //const currentUser = auth.currentUser;
    //console.log(" quizData.userUid: " + currentUser.userUid);
    console.log(" quizData.userEmail:" + quizData.userEmail);
    
    try {
      // Convert input values back to UTC for Firestore
      const tournamentData = {
        quizId,
        userEmail: quizData.userEmail, // Use userEmail from quizData
        startTime: convertToUTC(startTime), // Convert local to UTC
        endTime: convertToUTC(endTime), // Convert local to UTC
        status: 'upcoming',
        participants: [],
        tourneyInfo: tourneyInfo,
        public: true,
      };

      if (tournamentId) {
        // Update existing tournament
        const tournamentDocRef = doc(firestore, 'tournaments', tournamentId);
        await setDoc(tournamentDocRef, tournamentData, { merge: true });
        console.log('Tournament successfully updated');
      } else {
        // Create a new tournament
        await addDoc(collection(firestore, 'tournaments'), tournamentData);
        console.log('Tournament successfully created');
      }

      navigate(`/quizpage?id=${quizId}`);
    } catch (error) {
      console.error('Error creating/updating tournament:', error);
      setErrorMessage('Failed to save the tournament. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to handle tournament deletion
  const handleDeleteTournament = async () => {
    if (!tournamentId) return; // No tournament to delete
    setDeleteLoading(true);

    try {
      await deleteDoc(doc(firestore, 'tournaments', tournamentId));
      console.log('Tournament successfully deleted');
      navigate(`/quizpage?id=${quizId}`);
    } catch (error) {
      console.error('Error deleting tournament:', error);
      setErrorMessage('Failed to delete the tournament. Please try again.');
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-white text-2xl cursor-pointer"
            onClick={handleGoBack} // Back to quiz page
          />
        </div>
        <h1 className="text-xl font-bold">Tournament Curator</h1>
        <div></div>
      </nav>

      {quizData && (
        <div className="relative mb-12 mt-8 w-full max-w-3xl">
          <h2 className="text-4xl font-bold text-gray-900 bg-gray-200 p-6 rounded-lg shadow-lg text-center">
            {desanitizeQuizName(quizData.quizName)}
          </h2>
        </div>
      )}

      {isCreator ? (
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg text-left">
          <h3 className="text-2xl font-semibold mb-4">{tournamentId ? 'Edit Tournament' : 'Create Tournament'}</h3>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">Curator Info</label>
            <textarea
              value={tourneyInfo}
              onChange={(e) => setTourneyInfo(e.target.value)}
              maxLength={256}
              className="w-full p-3 border rounded-lg"
              placeholder="Enter curator info (max 256 characters)"
            />
            <p className="text-sm text-gray-500">{tourneyInfo.length}/256 characters</p>
          </div>
          {/* Start Time */}
          <label className="block text-lg font-medium mb-2" htmlFor="startTime">
            Start Time:
          </label>
          <input
            id="startTime"
            type="datetime-local"
            className="w-full p-3 border rounded-lg mb-4"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />

          {/* End Time */}
          <label className="block text-lg font-medium mb-2" htmlFor="endTime">
            End Time:
          </label>
          <input
            id="endTime"
            type="datetime-local"
            className="w-full p-3 border rounded-lg mb-4"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />

          {/* Create/Update Tournament Button */}
          <button
            onClick={handleCreateOrUpdateTournament}
            className={`w-full bg-[#9C27B0] text-white p-4 rounded-lg font-bold transition-all ${
              loading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:bg-purple-700'
            }`}
            disabled={loading}
          >
            {loading ? 'Saving...' : tournamentId ? 'Update Tournament' : 'Create Tournament'}
          </button>

          {/* Delete Tournament Button (only show if editing a tournament) */}
          {tournamentId && (
            <button
              onClick={handleDeleteTournament}
              className={`w-full bg-red-600 text-white p-4 rounded-lg font-bold mt-4 transition-all ${
                deleteLoading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:bg-red-700'
              }`}
              disabled={deleteLoading}
            >
              {deleteLoading ? 'Deleting...' : 'Delete Tournament'}
            </button>
          )}

          {errorMessage && <p className="text-red-600 mt-4">{errorMessage}</p>}
        </div>
      ) : (
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg text-center">
          <p className="text-lg font-medium text-gray-700">You are not authorized to create or edit this tournament.</p>
        </div>
      )}
    </div>
  );
};

export default TournamentCurator;


