// App.js

import React, { useEffect, useState } from 'react';
import './App.css'; // Ensure Tailwind directives are included in this CSS file
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import HomePage from './HomePage';
import LandingPage from './LandingPage';
import LearningFocusPage from './LearningFocusPage';
import TriviaScreen from './TriviaScreen';
import TriviaSummaryScreen from './TriviaSummaryScreen';
import QFeedbackPage from './QFeedbackPage';
import QuizzesListLocal from './QuizzesListLocal';
import TriviaListLocal from './TriviaListLocal';
import ProfileSettingsPage from './ProfileSettingsPage';
import CreateScreenName from './CreateScreenName';
import QuizGenerationProgressPage from './QuizGenerationProgressPage';
import QuizPage from './QuizPage';
import PlayQuiz from './PlayQuiz';
import QuizzesViewer from './QuizzesViewer';
import MyQuizzesViewer from './MyQuizzesViewer';
import UserScores from './UserScores';
import TournamentCurator from './TournamentCurator';
import QuizCurator from './QuizCurator';
import TourneyResults from './TourneyResults';
import ScreenNameContext from './ScreenNameContext';  
import QuiznectAdmin from './QuiznectAdmin';  
import CuratorPromotion from './CuratorPromotion';  
import QuizEditor from './QuizEditor';
import QuizLeaderboard from './QuizLeaderboard';
import LeaderboardPage from './LeaderboardPage';
import DisclaimerPage from './DisclaimerPage';
import CodeSyndicationScreen from './CodeSyndicationScreen';
import LocalTriviaSummaryScreen from './LocalTriviaSummaryScreen';

import AssignAdmin from './AssignAdmin';
import AdminRoute from './AdminRoute';
import NotAuthorized from './NotAuthorized';

import { AuthModalProvider } from './contexts/AuthModalContext';
import { AlertProvider } from './contexts/AlertContext'; // Import AlertProvider


// Component to handle page view tracking
function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    // Log page view event
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title,
    });
  }, [location]);

  return null;
}

function App() {
  const [screenName, setScreenName] = useState('');

  return (
    <Router>
      <AuthModalProvider>
        <AlertProvider>
          <ScreenNameContext.Provider value={{ screenName, setScreenName }}>
            <AnalyticsTracker />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/learning-focus" element={<LearningFocusPage />} />
              <Route path="/trivia-screen" element={<TriviaScreen />} />
              <Route path="/trivia-summary" element={<TriviaSummaryScreen />} />
              <Route path="/q-feedback" element={<QFeedbackPage />} />
              <Route path="/quizzes-free" element={<QuizzesListLocal />} />
              <Route path="/trivia" element={<TriviaListLocal />} />
              <Route path="/profile" element={<ProfileSettingsPage />} />
              <Route path="/create-screen-name" element={<CreateScreenName />} />
              <Route path="/quiz-generation-progress" element={<QuizGenerationProgressPage />} />
              <Route path="/quizpage" element={<QuizPage />} />
              <Route path="/play-quiz" element={<PlayQuiz />} />
              <Route path="/quizzes-viewer" element={<QuizzesViewer />} />
              <Route path="/my-quizzes-viewer" element={<MyQuizzesViewer />} />
              <Route path="/user-scores" element={<UserScores />} />
              <Route path="/tournament-curator" element={<TournamentCurator />} />
              <Route path="/quiz-curator" element={<QuizCurator />} />
              <Route path="/tourney-results" element={<TourneyResults />} />
              
              <Route path="/curator-promotion" element={<CuratorPromotion />} />
              <Route path="/quiz-editor" element={<QuizEditor />} />
              <Route path="/leaderboard" element={<QuizLeaderboard />} />
              <Route path="/leaderboards" element={<LeaderboardPage />} />
              <Route path="/disclaimer" element={<DisclaimerPage />} />
              <Route path="/code-syndication" element={<CodeSyndicationScreen />} />
              <Route path="/local-trivia-summary" element={<LocalTriviaSummaryScreen />} />

              <Route
                path="/admin"
                element={
                  <AdminRoute>
                    <QuiznectAdmin />
                  </AdminRoute>
                }
              />
              {/* Assign Admin Route Protected by AdminRoute */}
              <Route
                path="/assign-admin"
                element={
                  <AdminRoute>
                    <AssignAdmin />
                  </AdminRoute>
                }
              />
              <Route path="/not-authorized" element={<NotAuthorized />} />
              
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ScreenNameContext.Provider>
        </AlertProvider>
      </AuthModalProvider>
    </Router>
  );
}

export default App;

// was: <Route path="/admin" element={<QuiznectAdmin />} />