export const fetchQuizQuestions = async (quizFile) => {
    if (!quizFile) {
        console.log("ERROR: fetchQuizQuestions: no quizFile")
        return [];
    } else {
        console.log("fetchQuizQuestions: quizFile: " + quizFile);
    }
    try {
      const response = await fetch(`https://storage.googleapis.com/quiznect-quiz-storage-bucket/${quizFile}`);
      if (!response.ok) {
        throw new Error('Failed to fetch quiz questions.');
      }
      const data = await response.json();
      return data.questions;
    } catch (error) {
      console.error('Error fetching quiz questions:', error);
      return [];
    }
};