// components/PromoCodeModal.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PromoCodeModal = ({ isOpen, onClose, onRedeem, message }) => {
  const navigate = useNavigate();

  // Close modal on Esc key press
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    } else {
      window.removeEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm mx-4"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <h2 className="text-2xl font-semibold mb-4 text-center">Redeem Promo Code</h2>
        <p className="text-gray-700 mb-6">{message}</p>
        <div className="flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Enter your promo code"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={(e) => onRedeem.setPromoCode(e.target.value)}
            value={onRedeem.promoCode}
          />
          {onRedeem.error && <p className="text-red-600">{onRedeem.error}</p>}
          {onRedeem.success && <p className="text-green-600">{onRedeem.success}</p>}
          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={onRedeem.handleRedeem}
              className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-opacity ${
                onRedeem.isRedeeming ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={onRedeem.isRedeeming}
            >
              {onRedeem.isRedeeming ? 'Redeeming...' : 'Redeem'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PromoCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRedeem: PropTypes.object.isRequired,
  message: PropTypes.string,
};

PromoCodeModal.defaultProps = {
  message: "Enter your promo code below to redeem your free quiz.",
};

export default PromoCodeModal;
