// QuizEditor.js
import React, { useState, useEffect } from 'react';
import { firestore } from './firebase'; 
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'; 
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from './Navbar'; 
import LoadingSpinner from './LoadingSpinner'; 
import { fetchQuizQuestions } from './utils/utils'; 

function QuizEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const quizId = location.state?.quizId || null; 
  const [quizMetadata, setQuizMetadata] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [disabledQuestionIds, setDisabledQuestionIds] = useState([]);
  const [expandedQuestions, setExpandedQuestions] = useState({}); 

  useEffect(() => {
    const fetchQuizData = async () => {
      if (!quizId) {
        setError('No quiz ID provided.');
        setLoading(false);
        return;
      }

      try {
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (!quizDocSnap.exists()) {
          setError('Quiz not found.');
          setLoading(false);
          return;
        }

        const fetchedQuizMetadata = quizDocSnap.data();
        setQuizMetadata(fetchedQuizMetadata);
        setDisabledQuestionIds(fetchedQuizMetadata.disabledQuestionIds || []);

        const fetchedQuestions = await fetchQuizQuestions(fetchedQuizMetadata.storagePath);

        setQuestions(fetchedQuestions); // Show all questions, regardless of disabled status
      } catch (err) {
        console.error('Error fetching quiz data:', err);
        setError('Failed to load quiz data.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [quizId]);

  const handleToggleQuestion = async (questionId) => {
    let updatedDisabledQuestionIds = [...disabledQuestionIds];
    let isCurrentlyDisabled = updatedDisabledQuestionIds.includes(questionId);

    if (isCurrentlyDisabled) {
      updatedDisabledQuestionIds = updatedDisabledQuestionIds.filter((id) => id !== questionId);
    } else {
      updatedDisabledQuestionIds.push(questionId);
    }

    try {
      const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
      await updateDoc(quizDocRef, { disabledQuestionIds: updatedDisabledQuestionIds });
      setDisabledQuestionIds(updatedDisabledQuestionIds);
    } catch (err) {
      console.error('Error updating question status:', err);
      alert('Failed to update question status.');
    }
  };

  const toggleQuestionDetails = (questionId) => {
    setExpandedQuestions((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  const handleGoBack = () => {
    navigate(`/quiz-curator`, {
        state: { quizId: quizId },
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <Navbar title="Edit Quiz" leftIcon="arrow-left" onLeftIconClick={handleGoBack} />

      <div className="mt-8 w-full max-w-3xl">
        {/* Quiz Metadata Section */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-bold mb-4">{quizMetadata.quizName}</h2>
        </div>

        {/* Questions Management Section */}
        <div className="bg-white p-6 rounded-lg shadow-md w-full">
          <h3 className="text-xl font-bold mb-4">Enable/Disable Questions</h3>
          {questions.length === 0 ? (
            <p className="text-gray-700">No questions available.</p>
          ) : (
            <div className="space-y-4">
              {questions.map((question) => (
                <div key={question.id} className="flex flex-col items-start">
                 <div className="flex w-full justify-between items-center">
                    <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                        <input
                            type="checkbox"
                            checked={!disabledQuestionIds.includes(question.id)}
                            onChange={() => handleToggleQuestion(question.id)}
                            className="h-5 w-5 appearance-none border border-gray-300 rounded bg-white checked:bg-green-600 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        />
                        </div>
                        <p className="text-base font-medium">{question.question}</p>
                    </div>
                    <button
                        className="ml-auto"
                        onClick={() => toggleQuestionDetails(question.id)}
                    >
                        {expandedQuestions[question.id] ? (
                        <ChevronUpIcon className="h-6 w-6 text-gray-600" />
                        ) : (
                        <ChevronDownIcon className="h-6 w-6 text-gray-600" />
                        )}
                    </button>
                    </div>


                  {expandedQuestions[question.id] && (
                    <div className="mt-4 space-y-2 bg-gray-100 p-4 rounded-lg w-full">
                      <div>
                        <strong>Options:</strong>
                        <ul className="list-disc list-inside pl-4 pr-2">
                          {question.options.map((option, index) => (
                            <li key={index}>
                              {option} {option === question.correctAnswer && <span>(Correct)</span>}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <strong>Explanation:</strong>
                        <div dangerouslySetInnerHTML={{ __html: question.explanation }} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuizEditor;
