import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from './firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faThumbsUp, faThumbsDown, faHeart, faHome } from '@fortawesome/free-solid-svg-icons';

function QFeedbackPage() {
  const [reaction, setReaction] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [comment, setComment] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [clarity, setClarity] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { quizFile, questionIndex, questionText, previousScreenState } = location.state;

  const feedbackOptions = [
    { id: 'incorrect', label: 'The answer is incorrect' },
    { id: 'ambiguous', label: 'The answer is ambiguous' },
    { id: 'outdated', label: 'The information is wrong or outdated' },
  ];

  const toggleReason = (id) => {
    if (reasons.includes(id)) {
      setReasons(reasons.filter(reason => reason !== id));
    } else {
      setReasons([...reasons, id]);
    }
  };

  const handleSubmitFeedback = async () => {
    if (!reaction) {
      alert('Please select a reaction.');
      return;
    }

    try {
      const feedbackRef = collection(firestore, 'questionFeedback');
      await addDoc(feedbackRef, {
        quizFile,
        questionIndex,
        questionText,
        reaction,
        reasons,
        comment,
        difficulty,
        clarity,
        timestamp: serverTimestamp(),
      });

      alert('Feedback Submitted');
      navigate(-1, { state: previousScreenState });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again later.');
    }
  };

  const handleBack = () => {
    navigate(-1, { state: previousScreenState });
  };

  const handleGoHome = () => {
    navigate('/landing');
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex items-center justify-center relative" style={{ backgroundColor: '#9C27B0' }}>
        {/* Back Button on the Left */}
        <button 
          className="absolute left-4 text-white text-2xl cursor-pointer"
          onClick={handleBack}
          title="Back"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <h1 className="text-xl font-bold">Provide Feedback</h1>
      </nav>


      {/* Feedback Form */}
      <div className="p-6 w-full max-w-2xl mx-auto">
        <p className="mt-6 text-lg font-semibold">{questionText}</p>

        <div className="mt-6">
          <h4 className="text-lg font-medium">How was this question?</h4>
          <div className="flex justify-around mt-4 p-4 bg-gray-100 rounded-md">
            <button
              className={`p-4 rounded-full text-2xl ${reaction === 'thumbs-down' ? 'bg-[#9C27B0] text-white' : 'bg-gray-200'}`}
              onClick={() => setReaction('thumbs-down')}
            >
              <FontAwesomeIcon icon={faThumbsDown} />
            </button>
            <button
              className={`p-4 rounded-full text-2xl ${reaction === 'thumbs-up' ? 'bg-[#9C27B0] text-white' : 'bg-gray-200'}`}
              onClick={() => setReaction('thumbs-up')}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
            </button>
            <button
              className={`p-4 rounded-full text-2xl ${reaction === 'heart' ? 'bg-[#9C27B0] text-white' : 'bg-gray-200'}`}
              onClick={() => setReaction('heart')}
            >
              <FontAwesomeIcon icon={faHeart} />
            </button>
          </div>
        </div>

        <div className="mt-6">
          <h4 className="text-lg font-medium">Question difficulty:</h4>
          <div className="flex justify-around mt-4">
            {['Easy', 'Medium', 'Hard'].map(level => (
              <button
                key={level}
                className={`px-4 py-2 rounded-md ${difficulty === level ? 'bg-[#9C27B0] text-white' : 'bg-gray-200'}`}
                onClick={() => setDifficulty(level)}
              >
                {level}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-6">
          <h4 className="text-lg font-medium">Question clarity:</h4>
          <div className="flex justify-around mt-4">
            {['Unclear', 'Clear', 'Very Clear'].map(level => (
              <button
                key={level}
                className={`px-4 py-2 rounded-md ${clarity === level ? 'bg-[#9C27B0] text-white' : 'bg-gray-200'}`}
                onClick={() => setClarity(level)}
              >
                {level}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-6">
          <h4 className="text-lg font-medium">Comments</h4>
          <textarea
            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
            placeholder="Enter your comments here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        <div className="mt-6">
          <h4 className="text-lg font-medium">Issues with this question?</h4>
          {feedbackOptions.map(option => (
            <label key={option.id} className="flex items-center mt-2">
              <input
                type="checkbox"
                className="mr-2"
                checked={reasons.includes(option.id)}
                onChange={() => toggleReason(option.id)}
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>

        <button 
          className="w-full mt-8 py-3 bg-[#9C27B0] text-white rounded-md hover:bg-purple-700 transition duration-300"
          onClick={handleSubmitFeedback}
        >
          Submit Feedback
        </button>
      </div>
    </div>
  );
}

export default QFeedbackPage;
