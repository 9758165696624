// LeaderboardPage.js
import React from 'react';
import Navbar from './Navbar'; 
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon for the back button
import { allCategories } from './components/QuizCategories'; // Your categories data
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faCheckCircle, faHome } from '@fortawesome/free-solid-svg-icons';

const LeaderboardPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const handleViewUserScores = () => {
    navigate('/user-scores'); // Navigate to UserScores.js
  };

  return (
    <div className="leaderboard-page min-h-screen bg-gray-100 flex flex-col">
      <Navbar title="Leaderboards" leftIcon={<FaArrowLeft />} onLeftIconClick={handleGoBack} />

      {/* Main Content */}
      <div className="flex-1 p-6">
        

        {/* Button to view User Scores */}
        <div className="categories grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-6 mt-4">
            <button
            className="category-button bg-purple-600 text-white p-4 rounded-lg flex items-center space-x-3 hover:bg-purple-700 transition"
            style={{ backgroundColor: '#9C27B0' }}
            onClick={handleViewUserScores}            
            >
            <FontAwesomeIcon icon={faTrophy} size="lg" />
            <span className="text-lg">View My Scores</span>
            
            </button>
        </div>
        <div></div>
        
        <div className="categories grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {allCategories && allCategories.length > 0 ? (
            allCategories.map((category, index) => (
              <button 
                key={index} 
                className="category-button bg-purple-600 text-white p-4 rounded-lg flex items-center space-x-3 hover:bg-purple-700 transition"
                style={{ backgroundColor: '#9C27B0' }}
                onClick={() => navigate('/leaderboard', { state: { categoryName: category.name } })}
              >
                <FontAwesomeIcon icon={category.icon} size="lg" />
                <span className="text-lg">{category.name}</span>
              </button>
            ))
          ) : (
            <p className="text-center w-full text-xl text-gray-700">No categories available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPage;


/* <h1 className="text-3xl font-bold text-center mb-8 text-purple-600">Leaderboards</h1>
*/