// PlayQuiz.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHome, faTrophy, faArrowLeft, faClock } from '@fortawesome/free-solid-svg-icons';
import ScreenNameContext from './ScreenNameContext';
import LoadingSpinner from './LoadingSpinner';
import { useAuthModal } from './contexts/AuthModalContext'; 

function PlayQuiz() {
  const TIMER_AMOUNT = 30;
  const [quizData, setQuizData] = useState(null);
  const [quizMetadata, setQuizMetadata] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [sessionCorrectCount, setSessionCorrectCount] = useState(0);
  const [sessionIncorrectCount, setSessionIncorrectCount] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [timer, setTimer] = useState(TIMER_AMOUNT); // TIMER_AMOUNT seconds timer
  const navigate = useNavigate();
  const location = useLocation();
  const { openAuthModal } = useAuthModal(); 
  const [selectedQuizId, setQuizId] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [quizType, setQuizType] = useState('');
  const [userId, setUserId] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const { screenName } = useContext(ScreenNameContext);
  const [tagFilter, setTagFilter] = useState(null);

  const [isCreator, setIsCreator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // FNV-1a hash function
  const fnv1aHash = (str) => {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i);
      hash = Math.imul(hash, 0x01000193);
    }
    return hash >>> 0;
  };

  // Seeded random number generator (Mulberry32) - Still used for other quiz types if needed
  function mulberry32(a) {
    return function() {
      let t = a += 0x6D2B79F5;
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
  }

  // Seeded shuffle function - Still used for other quiz types if needed
  function shuffleArraySeed(array, seed) {
    const random = mulberry32(seed);
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(random() * currentIndex);
      currentIndex -= 1;

      // Swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  // At the top of your component, define the helper function
const getQuizStateDocRef = () => {
  if (userId && selectedQuizId && quizType) {
    return doc(
      firestore,
      'userQuizStates',
      `${userId}_${selectedQuizId}_${quizType}`
    );
  } else {
    return null;
  }
};


  // Combined useEffect to fetch quizId, metadata, and quiz data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const quizFile = searchParams.get('quizFile');
      const quizName = searchParams.get('quizName');
      const quizTypeParam = searchParams.get('quizType');
      const quizId = searchParams.get('quizId');
      const tagFilterParam = searchParams.get('tagFilter');
      const tournamentIdParam = searchParams.get('tournamentId');

      if (!quizId) {
        console.error('quizId is missing in URL parameters.');
        alert('Cannot load quiz without a valid quizId.');
        navigate('/landing');
        return;
      }

      setTagFilter(tagFilterParam);
      setQuizId(quizId);
      setQuizType(quizTypeParam);
      setTournamentId(tournamentIdParam);

      // Get current user
      const currentUser = auth.currentUser;
      if (!currentUser) {
        openAuthModal('You need to be logged in to take the quiz.');
        setLoading(false);
        return;
      }

      const userId = currentUser.uid;
      setUserId(userId);

      try {
        // Fetch quiz metadata from Firestore
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (!quizDocSnap.exists()) {
          console.error('Quiz metadata does not exist.');
          alert('Quiz not found.');
          navigate('/landing');
          setLoading(false);
          return;
        }

        const fetchedQuizMetadata = quizDocSnap.data();
        setQuizMetadata(fetchedQuizMetadata);

        // Check if the current user is the creator of the quiz
        if (currentUser && currentUser.email === fetchedQuizMetadata.userEmail) {
          setIsCreator(true);
        }

        // Fetch quiz data from GCP storage
        console.log("Loading quiz data from:", quizFile);
        const response = await fetch(`https://storage.googleapis.com/quiznect-quiz-storage-bucket/${quizFile}`);
        const data = await response.json();

        let questions = data.questions;

        // Filter questions by tag if tagFilter is provided
        if (tagFilterParam) {
          questions = questions.filter((question) => question.tags.includes(tagFilterParam));
        }

        if (!fetchedQuizMetadata) {
          console.log(" fetchedQuizMetadata failed!");
        } else {
          console.log(" fetchedQuizMetadata: ", fetchedQuizMetadata);
          
          // Filter out disabled questions
          if (fetchedQuizMetadata.disabledQuestionIds && fetchedQuizMetadata.disabledQuestionIds.length > 0) {
            questions = questions.filter(q => {
              const isEnabled = !fetchedQuizMetadata.disabledQuestionIds.includes(q.id);
              
              // Log only if the question is being filtered out
              if (!isEnabled) {
                console.log(`Filtering out question ID: ${q.id}`);
              }
              
              return isEnabled;
            });
          }
        }
        
        // Ensure at least 10 questions are available
        if (questions.length < 10) {
          alert('This quiz does not have enough enabled questions to start.');
          if (quizId) {
            navigate(`/quizpage?id=${quizId}`);
          } else {
            navigate('/landing');
          }
          setLoading(false);
          return;
        }

        // Reference to the quiz state document
        const quizStateDocRef = doc(firestore, 'userQuizStates', `${userId}_${quizId}_${quizTypeParam}`);
        const quizStateDoc = await getDoc(quizStateDocRef);

        if (quizTypeParam === 'randomQuiz10Timed') {
          if (quizStateDoc.exists()) {
            console.log("randomQuiz10Timed - quizStateDoc.exists");
            // Quiz state exists, load selectedQuestions and other state
            const savedState = quizStateDoc.data();
            console.log("savedState: " + savedState);
            setCurrentQuestionIndex(savedState.currentQuestionIndex);
            setUserAnswers(savedState.userAnswers);
            setSessionCorrectCount(savedState.sessionCorrectCount);
            setSessionIncorrectCount(savedState.sessionIncorrectCount);

            if (savedState.selectedQuestions && savedState.selectedQuestions.length === 10) {
              
              setQuizData({ questions: savedState.selectedQuestions, quizName });
              console.log("setQuizData({ questions:" + savedState.selectedQuestions);

            } else {
              console.warn('Selected questions are missing or incomplete in Firestore. Selecting new questions.');
              // Perform random shuffle and select new questions
              const shuffledQuestions = shuffleArray(questions);
              const selectedQuestions = shuffledQuestions.slice(0, 10);
              setQuizData({ questions: selectedQuestions, quizName });

              // Update Firestore with new selectedQuestions and reset state
              const questionStartTime = new Date().toISOString();

              await updateDoc(quizStateDocRef, {
                selectedQuestions: selectedQuestions,
                currentQuestionIndex: 0,
                userAnswers: [],
                sessionCorrectCount: 0,
                sessionIncorrectCount: 0,
                timerStartTime: questionStartTime, // New field
                timestamp: serverTimestamp(),
              });

              // Reset local state
              setCurrentQuestionIndex(0);
              setUserAnswers([]);
              setSessionCorrectCount(0);
              setSessionIncorrectCount(0);
            }

            // Determine remaining time based on timerStartTime
            const timerStartTime = savedState.timerStartTime ? new Date(savedState.timerStartTime) : new Date();
            const now = new Date();
            const elapsedSeconds = Math.floor((now - timerStartTime) / 1000);
            const timeLimit = TIMER_AMOUNT; // TIMER_AMOUNT seconds per question

            if (savedState.userAnswers && savedState.userAnswers.some(a => a.questionIndex === savedState.currentQuestionIndex)) {
              // Question has been answered or timed out
              console.log("Question has been answered or timed out savedState.userAnswers:" + savedState.userAnswers);
              const currentAnswer = savedState.userAnswers.find(a => a.questionIndex === savedState.currentQuestionIndex);
              console.log("Question has been answered or timed out currentAnswer:" + currentAnswer);
              setSelectedAnswer(currentAnswer.selectedAnswer);
              setIsCorrect(currentAnswer.isCorrect);
              setTimer(0); // No timer needed
            } else {
              // Question is active, set remaining time
              console.log("Question is active, set remaining time");
              const remainingTime = timeLimit - elapsedSeconds;
              if (remainingTime <= 0) {
                // Time has already expired, mark as timeout
                await handleAnswerSelection(null);
              } else {
                setTimer(remainingTime);
              }
            }
          } else {
            // No existing state, perform random shuffle and select questions
            console.log("randomQuiz10Timed - No existing state, perform random shuffle and select questions");
            const shuffledQuestions = shuffleArray(questions);
            const selectedQuestions = shuffledQuestions.slice(0, 10);
            setQuizData({ questions: selectedQuestions, quizName });

            // Record the start time
            const questionStartTime = new Date().toISOString();

            // Create initial state in Firestore with selectedQuestions and start time
            await setDoc(quizStateDocRef, {
              userId,
              quizId,
              quizType: quizTypeParam,
              quizName,
              screenName,
              currentQuestionIndex: 0,
              userAnswers: [],
              sessionCorrectCount: 0,
              sessionIncorrectCount: 0,
              selectedQuestions: selectedQuestions,
              timerStartTime: questionStartTime, // New field
              timestamp: serverTimestamp(),
            });
          }
        } else {
          // Handle other quiz types (e.g., 'Quiz10Tournament') as per existing logic
          // You can implement similar persistence if needed
          // For simplicity, keeping existing behavior
          console.log("Handle other quiz types (e.g., 'Quiz10Tournament') as per existing logic");
          if (quizStateDoc.exists()) {
            // Load existing state
            const savedState = quizStateDoc.data();

            setCurrentQuestionIndex(savedState.currentQuestionIndex);
            setUserAnswers(savedState.userAnswers);
            setSessionCorrectCount(savedState.sessionCorrectCount);
            setSessionIncorrectCount(savedState.sessionIncorrectCount);
          } else {
            // No existing state, create initial state in Firestore
            await setDoc(quizStateDocRef, {
              userId,
              quizId,
              quizType: quizTypeParam,
              quizName,
              screenName,
              currentQuestionIndex: 0,
              userAnswers: [],
              sessionCorrectCount: 0,
              sessionIncorrectCount: 0,
              timestamp: serverTimestamp(),
            });
          }

          // Shuffle based on existing logic for other quiz types
          if (quizTypeParam === 'Quiz10Tournament' && data.questions) {
            const seed = fnv1aHash(tournamentIdParam);
            const allQuestions = questions.slice();
            const shuffledQuestions = shuffleArraySeed(allQuestions, seed);
            questions = shuffledQuestions.slice(0, 10);
          } // else, keep all questions

          setQuizData({ questions, quizName });
        }
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        alert('Failed to load quiz data. Please try again later.');
        navigate('/landing');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.search, navigate, openAuthModal]);



  // Timer effect
  useEffect(() => {
    let interval = null;

    // Determine if the current question has been answered
    const currentAnswer = userAnswers.find(
      (answer) => answer.questionIndex === currentQuestionIndex
    );

    if (isCorrect === null && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            handleAnswerSelection(null); // Time's up
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, userAnswers, currentQuestionIndex, isCorrect]);

  useEffect(() => {
    if (quizData) {
      const question = quizData.questions[currentQuestionIndex];
      const correctAnswer = question.correctAnswer;
      const options = question.options;

      // Shuffle options with the helper function
      const shuffled = shuffleOptions(options, correctAnswer);

      setShuffledOptions(shuffled);
      setCorrectAnswer(correctAnswer);
    }
  }, [quizData, currentQuestionIndex]);

  // Existing shuffleArray function (Fisher-Yates Shuffle)
  const shuffleArray = (array) => {
    // Make a copy to avoid mutating the original array
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // New helper function to handle shuffling with "All of the above" at the bottom
  const shuffleOptions = (options, correctAnswer) => {
    if (correctAnswer === "All of the above" || correctAnswer === "All the above" || correctAnswer === "None of the above"  ) {
      // Separate "All of the above" from the other options
      const allOfTheAboveOption = options.find(option => option === correctAnswer);
      const otherOptions = options.filter(option => option !== correctAnswer);
      
      // Shuffle the other options
      const shuffledOtherOptions = shuffleArray(otherOptions);
      
      // Append "All of the above" at the end
      return [...shuffledOtherOptions, allOfTheAboveOption];
    } else {
      // Shuffle all options normally
      return shuffleArray(options);
    }
  };

  const handleAnswerSelection = async (answer) => {
    if (selectedAnswer !== null)  { // Prevent re-answering
      console.log('selectedAnswer already answered.');
      return;
    }; 
    if (!userId || !selectedQuizId || !quizType) {
      console.error('User ID, Quiz ID, or Quiz Type is not defined.');
      return;
    }
    setSelectedAnswer(answer);
    const question = quizData.questions[currentQuestionIndex];
    const answerIsCorrect = answer === question.correctAnswer;
    setIsCorrect(answerIsCorrect);
  
    // Update userAnswers
    const updatedUserAnswers = [
      ...userAnswers,
      {
        questionIndex: currentQuestionIndex,
        question: question.question,
        selectedAnswer: answer,
        correctAnswer: question.correctAnswer,
        isCorrect: answerIsCorrect,
      },
    ];
    setUserAnswers(updatedUserAnswers);
  
    const newCorrectCount = answerIsCorrect ? sessionCorrectCount + 1 : sessionCorrectCount;
    const newIncorrectCount = !answerIsCorrect ? sessionIncorrectCount + 1 : sessionIncorrectCount;
    setSessionCorrectCount(newCorrectCount);
    setSessionIncorrectCount(newIncorrectCount);
  
    // Use the helper function to get quizStateDocRef
    const quizStateDocRef = getQuizStateDocRef();
  
    // Update Firestore
    if (quizStateDocRef) {
      await updateDoc(quizStateDocRef, {
        userAnswers: updatedUserAnswers,
        sessionCorrectCount: newCorrectCount,
        sessionIncorrectCount: newIncorrectCount,
        timerStartTime: null, // Clear timer start time since question is answered
        timestamp: serverTimestamp(),
      });
    }
  };
  
  

  const handleNextQuestion = async () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      const newQuestionIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newQuestionIndex);
      setSelectedAnswer(null);
      setIsCorrect(null);
      setTimer(TIMER_AMOUNT); // Start timer for new question
  
      // Record the start time
      const questionStartTime = new Date().toISOString();
  
      // Use the helper function to get quizStateDocRef
      const quizStateDocRef = getQuizStateDocRef();
  
      // Update Firestore with new question index and start time
      if (quizStateDocRef) {
        await updateDoc(quizStateDocRef, {
          currentQuestionIndex: newQuestionIndex,
          selectedAnswer: null,
          isCorrect: null,
          timerStartTime: questionStartTime, // New field
          timestamp: serverTimestamp(),
        });
      }
    } else {
      handleExitQuiz();
    }
  };
  

  const handleExitQuiz = async () => {
    const totalPoints = sessionCorrectCount * 10;
    const totalScore = totalPoints;
  
    // Check if user is logged in
    const currentUser = auth.currentUser;
  
    if (currentUser) {
      const userId = currentUser.uid;
      const userEmail = currentUser.email;
      console.log('In handleExitQuiz - tournamentId: ' + tournamentId);
  
      // Use the helper function to get quizStateDocRef
      const quizStateDocRef = getQuizStateDocRef();
  
      try {
        const userScoresRef = collection(firestore, 'userScores');
        await addDoc(userScoresRef, {
          userId: userId,
          userEmail: userEmail,
          screenName: screenName,
          quizId: selectedQuizId || quizData.quizId || '',
          quizName: quizData.quizName,
          quizType: quizType,
          score: totalScore,
          correctCount: sessionCorrectCount,
          incorrectCount: sessionIncorrectCount,
          timestamp: serverTimestamp(),
          tournamentId: tournamentId,
        });
        console.log('Score saved successfully.');
  
        // Delete quiz state from Firestore
        if (quizStateDocRef) {
          await deleteDoc(quizStateDocRef);
        }
      } catch (error) {
        console.error('Error saving score:', error);
      }
    } else {
      console.log('User not logged in. Score not saved.');
    }
  
    navigate('/trivia-summary', {
      state: {
        correctCount: sessionCorrectCount,
        incorrectCount: sessionIncorrectCount,
        completionPercentage,
        totalScore,
        totalPoints, // Add this
        totalBonusPoints: 0, // Adjust if you have bonus points logic
        userAnswers,
        quizType,
        quizName: quizData.quizName, // Add this
        quizId: selectedQuizId, // Add this
        tournamentId: tournamentId,
      },
    });
  };
  

  const handleGoHome = () => {
    navigate('/landing');
  };

  const handleGoBackToQuizPage = () => {
    if (selectedQuizId) {
      navigate(`/quizpage?id=${selectedQuizId}`);
    } else {
      console.error('quizId is not available');
      alert('Cannot navigate back to Quiz Page. quizId is missing.');
    }
  };

  if (loading || !quizData || quizData.questions.length === 0 || !quizMetadata) {
    return <LoadingSpinner />;
  }

  const handleFlagQuestion = () => {
    navigate('/q-feedback', {
      state: {
        quizFile: quizData.quizFile, // Changed from location.state.quizFile
        questionIndex: currentQuestionIndex,
        questionText: quizData.questions[currentQuestionIndex].question,
        quizState: {
          currentQuestionIndex,
          selectedAnswer,
          sessionCorrectCount,
          sessionIncorrectCount,
          completionPercentage,
          shuffledOptions,
          quizData,
          timer,
        },
      },
    });
  };

  const question = quizData.questions[currentQuestionIndex];
  const explanationHtml = selectedAnswer ? question.explanation : '';

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        {/* Left Side: Home Button and Timer */}
        <div className="flex items-center">
          <FontAwesomeIcon 
            icon={faHome} 
            className="text-white text-2xl cursor-pointer mr-4" 
            onClick={handleGoHome} 
            title="Home"
          />
        </div>

        {/* Center: Question Indicator */}
        <h1 className="text-xl font-bold">
          {quizType === 'Quiz10Tournament' && (
            <FontAwesomeIcon icon={faTrophy} className="text-yellow-400 text-2xl mr-2" />
          )}
          Question {currentQuestionIndex + 1} of {quizData.questions.length}
        </h1>

        {/* Right Side: Timer & Flag Button */}
        <div className="flex items-center space-x-4">
          {/* Timer Display */}
          {isCorrect === null && (
            <div className="flex items-center">
              <FontAwesomeIcon icon={faClock} className="text-white text-lg mr-2" />
              <span className="text-lg font-bold">{timer}</span>
            </div>
          )}
          {selectedAnswer !== null && (
            <button 
              className="bg-[#9C27B0] text-white p-2 rounded-lg cursor-pointer" 
              onClick={handleFlagQuestion}
              title="Flag this question"
            >
              <FontAwesomeIcon icon={faFlag} />
            </button>
          )}
        </div>
      </nav>


      {/* Quiz Question */}
      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg">
        <div 
          className="text-lg font-semibold text-center mb-4" 
          dangerouslySetInnerHTML={{ __html: question.question.replace(/\n/g, '<br/>') }} 
        />

        <div className="grid gap-4">
          {shuffledOptions.map((option, index) => {
            // Determine if the current question has been answered
            const isAnswered = userAnswers.some(
              (answer) => answer.questionIndex === currentQuestionIndex
            );

            const isCorrectOption = option === correctAnswer;

            let optionClass = 'bg-[#9C27B0] text-white p-4 rounded-lg text-center ';
            if (selectedAnswer === option) {
              optionClass += isCorrect ? 'bg-green-600' : 'bg-red-600';
            } else if (isAnswered && isCorrectOption) {
              optionClass += 'bg-green-600';
            } else if (!isAnswered) {
              optionClass += 'hover:bg-purple-700 cursor-pointer';
            } else {
              optionClass += 'bg-gray-400 cursor-default';
            }

            return (
              <div
                key={index}
                className={`${optionClass} transition duration-300`}
                onClick={() => !isAnswered && handleAnswerSelection(option)}
              >
                <div dangerouslySetInnerHTML={{ __html: option.replace(/\n/g, '<br/>') }} />
              </div>
            );
          })}
        </div>

        {isCorrect !== null && (
          <>
            {isCorrect === false && selectedAnswer === null && (
              <div className="mt-2 text-red-600 font-semibold text-center">
                Failed: No answer given before timeout.
              </div>
            )}
            <div className="flex justify-center mt-6">
              {currentQuestionIndex < quizData.questions.length - 1 ? (
                <button 
                  className="bg-[#9C27B0] text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300" 
                  onClick={handleNextQuestion}
                >
                  Next
                </button>
              ) : (
                <button 
                  className="bg-[#9C27B0] text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300" 
                  onClick={handleExitQuiz}
                >
                  Finish Quiz
                </button>
              )}
            </div>
            {explanationHtml && explanationHtml.length > 0 && (
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-6">
                <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
              </div>
            )}
            {question.url && (
              <div className="text-center mt-4">
                <a 
                  href={question.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-600 underline"
                >
                  {question.url.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
          </>
        )}
      </div>

      {/* Centered Quiz Name Button with Back Arrow */}
      <div className="mt-4 mb-6 flex items-center justify-center">
        <button 
          onClick={handleGoBackToQuizPage} 
          className="flex items-center bg-white text-purple-900 px-6 py-3 rounded-lg shadow-md transition duration-300"
          title="Back to Quiz Details"
        >
          <FontAwesomeIcon 
            icon={faArrowLeft} 
            className="text-purple-900 text-2xl cursor-pointer mr-4" // Adjusted spacing with "mr-4"
            title="Back"
          />
          <span className="ml-2">{quizData.quizName.replace(/_/g, ' ')}</span> {/* Adjusted spacing */}
        </button>
      </div>

      {tagFilter && (
        <div className="mt-2 mb-4 text-sm font-semibold text-purple-900">
          Topic: {tagFilter}
        </div>
      )}
    </div>
  );
}

export default PlayQuiz;






/*
<div className="flex items-center space-x-4">
{!selectedAnswer && (
  <div className="text-center mt-4">
      <span className="text-xl font-bold">
          {timer}
      </span>
  </div>
)}
</div>



 <div className="mt-2 flex items-center">
        {quizType === 'Quiz10Tournament' && (
        <>
            <FontAwesomeIcon icon={faTrophy} className="text-yellow-400 text-2xl mr-2" />
            <span className="text-xl font-semibold">Tournament</span>
        </>
        )}
        {quizType === 'randomQuiz10Timed' && (
            <span className="text-xl font-semibold">Random Quiz</span>
        )}
        </div>
*/
